import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Select, Spin } from 'antd';
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import dayjs from 'dayjs';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { ReactComponent as QuestionCircleIcon } from 'images/question-round.svg';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AlertMessage, { AlertInfo } from 'components/AlertMessage/AlertMessage';
import BoxPopover from 'components/BoxPopover/BoxPopover';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import InfoBanner from 'components/InfoBanner/InfoBanner';
import StyledCheckbox from 'components/StyledCheckbox/StyledCheckbox';
import InputForm from 'components/InputForm/InputForm';
import PlanYear from 'model/PlanYear';
import BenefitGuide from 'model/BenefitGuide';
import {
  ALL_BENEFIT_CATEGORIES,
  BENGUIDE_NAME_DUPLICATE_MESSAGE,
  EMPTY_MESSAGE,
} from 'constants/commonConstants';
import RouteMap from 'routes/RouteMap';
import MultiSelectDropdown, {
  Option,
} from 'components/MultiSelectDropdown/MultiSelectDropdown';
import { formatPlanYearLabel } from 'utils/planYearUtils';
import BenefitGuideBasicInfo, {
  buildBenefitGuideBasicInfo,
} from 'model/BenefitGuideBasicInfo ';
import {
  defaultRouteTitles,
  RouteKeyConstants,
} from 'constants/routeKeyConstants';
import {
  ADDITIONAL_CONTENT_OPTIONS,
  BasicInfoAdditionalContent,
  BASIC_INFO_ERROR_MESSAGES,
  BASIC_INFO_EXIT_MESSAGES,
  BenefitSection,
  BENEFIT_TYPES,
  BENGUIDE_CREATE_ERRORS,
  DUPLICATE_BENGUIDE_NAME_ERROR_CODE,
  NHC_REGENERATE_WARNING_MSG,
  REMOVE_BENEFIT_MODAL_MESSAGE,
  REMOVE_CONTENT_MODAL_MESSAGE,
  UPDATE_NHC_CANCEL_TEXT,
  UPDATE_NHC_CONFIRMATION_TEXT,
  UPDATE_NHC_MODAL_DESCRIPTION,
} from 'modules/home/constants';
import {
  CLOSE_CREATE_BENGUIDE_MODAL,
  CREATE_BENGUIDE_CHANNEL,
  EDIT_BENGUIDE_CHANNEL,
  EDIT_BENGUIDE_STARTED,
} from 'modules/clients/UPClient/UPClientConst';
import {
  createBenguide,
  findEmployerById,
  getPlanYears,
  resetBenefitGuides,
  updateBasicInfo,
  findBenguideByHash,
  saveBenGuideFailed,
  saveBenGuideStarted,
  updateBasicInfoLocal,
  updateBasicInfoWithoutCheckListData,
} from 'modules/home/slices/basicInfoSlice';

import {
  getContentTypeFromValue,
  getPlansForBenefit,
  getBenefitFromValue,
  hasCustomContent,
  isBenefitClassChangeValid,
  isPlanYearChangeValid,
} from 'modules/home/util';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import useBenguide from 'hooks/benguide';

import CustomTitlePopover from 'modules/home/components/CustomTitlePopover/CustomTitlePopover';

import styles from './basicInfo.module.less';

const GUIDE_NAME_FIELD = 'guideName';
const PLAN_YEAR_FIELD = 'planYear';

const getOptionsFromPlanYears = (
  planYears: PlanYear[],
  selectedPlanYearId: string
) => {
  const currentPlanYear = planYears.find((py) => py.current);
  const selectedPlanYear = planYears.find((py) => py.id === selectedPlanYearId);
  if (currentPlanYear) {
    const currentStartDate = dayjs(currentPlanYear.startDate);
    // filter past plan years and sort
    const filteredPlanYears = planYears.filter(
      (planYear) => !currentStartDate.isAfter(dayjs(planYear.startDate))
    );

    if (selectedPlanYear && !filteredPlanYears.includes(selectedPlanYear)) {
      filteredPlanYears.push(selectedPlanYear);
    }
    filteredPlanYears.sort((a, b) => {
      return dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? 1 : -1;
    });
    return filteredPlanYears.map((planYear) => ({
      value: planYear.id,
      label: formatPlanYearLabel(planYear),
    }));
  }
  return planYears.map((planYear) => ({
    value: planYear.id,
    label: planYear.name,
  }));
};

const BasicInfo: FC = () => {
  const [benefitClassOptions, setBenefitGroupOptions] = useState<Option[]>([]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: 'error',
    message: '',
  });
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isRegenerateModalOpen, setIsRegenerateModalOpen] =
    useState<boolean>(false);
  const [updatedBenGuide, setUpdatedBenGuide] = useState<BenefitGuideBasicInfo>(
    {} as BenefitGuideBasicInfo
  );
  const [valueToDisable, setValueToDisable] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const [selectedBenefits, setSelectedBenefits] = useState<string[]>(
    Object.values(BENEFIT_TYPES).map((benefit) => benefit.value)
  );
  const [selectedContent, setSelectedContent] = useState<string[]>(
    Object.values(ADDITIONAL_CONTENT_OPTIONS).map((content) => content.value)
  );
  const [plansExist, setPlansExist] = useState<boolean>(false);

  const [params] = useSearchParams();

  const location = useLocation();

  const { latestRevision } = useBenguide();

  const isEdit = useMemo(() => {
    const isCreateMatch = matchPath(
      location.pathname,
      RouteMap.CREATE_BENGUIDE
    );
    return Boolean(!isCreateMatch);
  }, [location]);

  const {
    inProgress,
    requestType,
    benefitGuide: benguideData,
    error: apiError,
    basicInfo,
  } = useAppSelector((state) => state.basicInfo);
  const benguideId = useAppSelector((state) => state.basicInfo.masterId);
  const { urlHash, revision } = useBenguide();
  const employer = useAppSelector((state) => state.basicInfo.employer);
  const planYears = useAppSelector((state) => state.basicInfo.planYears);
  const { savedGuide } = useAppSelector((state) => state.basicInfo);
  const benGuide = useAppSelector((state) => state.benguide.data);
  const state = useAppSelector((state) => state);
  const {
    benguide: { customNavigationTitles },
  } = state;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const upClient = useUPClient();

  const employerId: string | null = useMemo(() => {
    if (isEdit) {
      return benguideData ? benguideData.employerId : '';
    }
    return params.get('employerId');
  }, [isEdit, benguideData, params]);

  const isValueToDisableBenefit = useMemo(() => {
    return Boolean(valueToDisable && getBenefitFromValue(valueToDisable.value));
  }, [valueToDisable]);

  const handlePlanYearChange = useCallback(
    (value: string) => {
      const selectedPlanYearObj = planYears.find((year) => year.id === value);
      if (selectedPlanYearObj) {
        setBenefitGroupOptions(
          selectedPlanYearObj.benefitGroups.map((group) => ({
            value: group,
            label: group,
          }))
        );
        if (selectedPlanYearObj.benefitGroups.length === 1) {
          form.setFieldsValue({
            benefitClasses: selectedPlanYearObj.benefitGroups,
          });
        }
      }
    },
    [form, planYears]
  );

  const isDiffSelectedBenefits = useCallback(() => {
    if (benguideData) {
      const benefits = Object.keys(
        Object.fromEntries(
          Object.entries(benguideData).filter(
            ([key, value]: any[]) =>
              ALL_BENEFIT_CATEGORIES.includes(key) && value.enabled
          )
        )
      );

      return !isEqual(benefits.sort(), selectedBenefits.sort());
    }
  }, [benguideData, selectedBenefits]);

  useEffect(() => {
    if (isEdit) {
      upClient.postMessage({
        channel: EDIT_BENGUIDE_CHANNEL,
        event: EDIT_BENGUIDE_STARTED,
        data: benguideId,
      });
    }
  }, [isEdit, benguideId, upClient]);

  useEffect(() => {
    if (urlHash) {
      dispatch(findBenguideByHash(urlHash, revision));
    }
  }, [dispatch, urlHash, revision]);

  useEffect(() => {
    if (employerId) {
      dispatch(findEmployerById(employerId));
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, employerId]);

  useEffect(() => {
    if (!isEdit && employer?.name) {
      form.setFieldsValue({
        guideName: `${employer.name} Benefits Guide`,
      });
      if (planYears && planYears.length === 1) {
        form.setFieldsValue({
          [PLAN_YEAR_FIELD]: planYears[0].id,
        });
        handlePlanYearChange(planYears[0].id);
      }
    }
  }, [employer, isEdit, form, planYears, handlePlanYearChange]);

  useEffect(() => {
    if (benguideData) {
      form.setFieldsValue({
        guideName: benguideData.name,
      });
      form.setFieldsValue({
        planYear: benguideData.planYearId,
      });
      handlePlanYearChange(benguideData.planYearId);
      form.setFieldsValue({
        benefitClasses: benguideData.benefitClasses,
      });
    }
  }, [benguideData, form, handlePlanYearChange]);

  useEffect(() => {
    if (savedGuide) {
      navigate(
        `/${savedGuide.urlHash}/${savedGuide.employerName}?revision=${latestRevision}`
      );
    }
  }, [savedGuide, navigate, dispatch, latestRevision]);

  useEffect(() => {
    if (apiError && requestType === saveBenGuideFailed.type) {
      if (apiError.code) {
        const errorMessage = BENGUIDE_CREATE_ERRORS.find(
          (error) => error.code === apiError.code
        );

        apiError.code === DUPLICATE_BENGUIDE_NAME_ERROR_CODE &&
          form.setFields([
            {
              name: GUIDE_NAME_FIELD,
              errors: [BENGUIDE_NAME_DUPLICATE_MESSAGE],
            },
          ]);

        errorMessage &&
          setAlertMessage({ type: 'error', message: errorMessage.message });
        setIsAlertVisible(true);
        return;
      }
      setAlertMessage({
        type: 'error',
        message: BASIC_INFO_ERROR_MESSAGES.alertMessage.apiError,
      });
      setIsAlertVisible(true);
    }
  }, [apiError, requestType, form]);

  const getEnabledBenefits = (benguideData: BenefitGuide) => {
    return Object.keys(BENEFIT_TYPES).filter((key) => {
      return (benguideData as any)[key] && (benguideData as any)[key].enabled;
    });
  };

  const getEnabledcontents = (benguideData: BenefitGuide) => {
    return Object.keys(ADDITIONAL_CONTENT_OPTIONS).filter((key) => {
      return (benguideData as any)[key] && (benguideData as any)[key].enabled;
    });
  };

  // loads data in edit mode
  useEffect(() => {
    if (benguideData) {
      const enabledBenefits = getEnabledBenefits(benguideData);
      const enabledContent = getEnabledcontents(benguideData);

      setSelectedContent([...enabledContent]);
      setSelectedBenefits([...enabledBenefits]);
    }
  }, [benguideData]);

  useEffect(() => {
    return () => {
      dispatch(resetBenefitGuides());
    };
  }, [dispatch]);

  useEffect(() => {
    const { guideName, planYear, benefitClasses } = form.getFieldsValue();
    if (employerId && benguideData && guideName) {
      const enabledBenefits = getEnabledBenefits(benguideData);
      const enabledContent = getEnabledcontents(benguideData);

      const benGuide: BenefitGuideBasicInfo = buildBenefitGuideBasicInfo(
        guideName,
        planYear,
        benefitClasses,
        employerId,
        enabledBenefits,
        enabledContent
      );

      if (benguideData) {
        benGuide.masterId = benguideData.masterId;
      }

      dispatch(updateBasicInfoLocal(benGuide));
    }
  }, [benguideData, dispatch, employerId, form]);

  useEffect(() => {
    setPlansExist(false);
    Object.values(BenefitSection).filter((section) => {
      const plans = get(state, `${section}.${section}Section.plans`, []).length;
      if (plans > 0) {
        setPlansExist(true);
      }
    });
  }, [state]);

  const handleValuesChange = async (changedValues: any, allValues: any) => {
    const { planYear, benefitClasses } = changedValues || {};

    if (planYear) {
      form.setFieldsValue({ ...allValues, benefitClasses: [] });
      handlePlanYearChange(planYear);
      return;
    }
    try {
      if (isAlertVisible) {
        await form.validateFields();
        if (isEmpty(benefitClasses)) {
          setIsAlertVisible(false);
        }
      }
    } catch (error: any) {
      const { errorFields = [] } = error;
      if (isEmpty(errorFields)) {
        if (isEmpty(benefitClasses)) {
          setIsAlertVisible(false);
        }
      }
    }

    const {
      guideName,
      planYear: currentPlanYear,
      benefitClasses: benClasses,
    } = allValues || {};
    if (employerId) {
      const benGuide: BenefitGuideBasicInfo = buildBenefitGuideBasicInfo(
        guideName,
        currentPlanYear,
        benClasses,
        employerId,
        selectedBenefits,
        selectedContent
      );

      if (benguideData) {
        benGuide.masterId = benguideData.masterId;
      }

      dispatch(updateBasicInfoLocal(benGuide));
    }
  };

  const handleSubmitFailed = () => {
    setIsAlertVisible(true);
    setAlertMessage({
      type: 'error',
      message: BASIC_INFO_ERROR_MESSAGES.alertMessage.formError,
    });
  };

  const handleSubmit = (values: any) => {
    if (selectedBenefits.length === 0) {
      setAlertMessage({
        type: 'error',
        message: BASIC_INFO_ERROR_MESSAGES.alertMessage.formError,
      });
      setIsAlertVisible(true);
      return;
    }
    const { guideName, planYear, benefitClasses } = values || {};
    if (employerId) {
      const benGuide: BenefitGuideBasicInfo = buildBenefitGuideBasicInfo(
        guideName,
        planYear,
        benefitClasses,
        employerId,
        selectedBenefits,
        selectedContent
      );

      if (benguideData) {
        benGuide.masterId = benguideData.masterId;

        if (
          benguideData.datesAndEligibility.enabled !==
          benGuide.datesAndEligibility
        ) {
          setUpdatedBenGuide(benGuide);
          setIsRegenerateModalOpen(true);
        } else {
          if (
            !(
              isDiffSelectedBenefits() &&
              selectedContent.includes('newHireCheckList')
            )
          ) {
            dispatch(
              updateBasicInfoWithoutCheckListData(
                benGuide,
                selectedContent,
                revision
              )
            );
          } else {
            setUpdatedBenGuide(benGuide);
            setIsRegenerateModalOpen(true);
          }
        }
      } else {
        dispatch(createBenguide(benGuide));
      }
    }
  };

  const handleConfirmModalCancel = () => {
    const benguide: BenefitGuideBasicInfo = buildBenefitGuideBasicInfo(
      benGuide && benGuide.name,
      benGuide && benGuide.planYearId,
      benGuide && benGuide.benefitClasses,
      benGuide && benGuide.employerId,
      selectedBenefits,
      selectedContent
    );
    benguide.masterId = benGuide && benGuide.masterId;

    if (!isEmpty(basicInfo) && !isEqual(basicInfo, benguide)) {
      setIsExitModalOpen(true);
    } else {
      handleCloseBasicInfo();
    }
  };

  const handleCloseBasicInfo = () => {
    form.resetFields();
    setIsExitModalOpen(false);
    if (isEdit && benguideData) {
      navigate(
        `/${benguideData.urlHash}/${benguideData.employerName}?revision=${latestRevision}`
      );
    } else {
      upClient.postMessage({
        channel: CREATE_BENGUIDE_CHANNEL,
        event: CLOSE_CREATE_BENGUIDE_MODAL,
        data: '',
      });
    }
  };

  const handleBenefitChange = (e: CheckboxChangeEvent) => {
    setIsAlertVisible(false);
    const { value, checked } = e.target;
    if (isEdit && benguideData) {
      const benefitIndex = selectedBenefits.findIndex(
        (benefit) => benefit === value
      );

      const benefitPlans = getPlansForBenefit(value, benguideData);

      if (!isEmpty(benefitPlans) && benefitIndex >= 0 && !checked) {
        setValueToDisable(getBenefitFromValue(value));
        setIsConfirmModalOpen(true);
        return;
      }
    }
    if (checked) {
      setSelectedBenefits([...selectedBenefits, value]);
      dispatch(updateBasicInfoLocal(benGuide));
      return;
    }
    onConfirmDisable(getBenefitFromValue(value));
    dispatch(updateBasicInfoLocal(benGuide));
  };

  const handleSelectedContentChange = (e: CheckboxChangeEvent) => {
    const { value, checked } = e.target;
    if (isEdit && benguideData) {
      const hasCustom = hasCustomContent(value, benguideData);
      if (!checked && hasCustom) {
        setValueToDisable(getContentTypeFromValue(value));
        setIsConfirmModalOpen(true);
        return;
      }
    }

    checked
      ? setSelectedContent([...selectedContent, value])
      : setSelectedContent(
          selectedContent.filter((selection) => selection !== value)
        );
    dispatch(updateBasicInfoLocal(benGuide));
  };

  const onConfirmDisable = (
    section: { value: string; label: string } | null
  ) => {
    if (section && getBenefitFromValue(section.value)) {
      const newSelections = selectedBenefits.filter(
        (benefit) => benefit !== section.value
      );
      setSelectedBenefits(newSelections);
      dispatch(updateBasicInfoLocal(benGuide));
    }
    if (section && getContentTypeFromValue(section.value)) {
      const updatedContentSelection = selectedContent.filter(
        (content) => content !== section.value
      );
      setSelectedContent(updatedContentSelection);
      dispatch(updateBasicInfoLocal(benGuide));
    }
    setValueToDisable(null);
    setIsConfirmModalOpen(false);
  };

  const cancelRegenerateNHC = () => {
    dispatch(
      updateBasicInfo(updatedBenGuide, selectedContent, false, revision)
    );
    setIsRegenerateModalOpen(false);
  };

  const regenerateNHC = () => {
    dispatch(updateBasicInfo(updatedBenGuide, selectedContent, true, revision));
  };

  const showRenamePopover = useCallback(
    (customTitleKey: string) => {
      const customTitle =
        customNavigationTitles?.data?.[
          customTitleKey as keyof typeof RouteKeyConstants
        ];
      const defaultTitle = defaultRouteTitles?.[customTitleKey];

      return customTitle && customTitle !== defaultTitle ? (
        <CustomTitlePopover customTitle={customTitle} />
      ) : (
        ''
      );
    },
    [customNavigationTitles.data]
  );

  return (
    <div className={styles.container}>
      {(inProgress && requestType !== saveBenGuideStarted.type) ||
      isEmpty(planYears) ? (
        <Spin />
      ) : (
        <div className={styles.content}>
          <BenefitGuideRefreshChannel />
          <InfoBanner
            header="Basic Information &amp; Content"
            description="We'll walk you through everything you need to create a great benefits guide. To start off, review the information below and click next when you're ready to continue"
            className={styles.infoBanner}
          />
          <div className={styles.formContainer}>
            {isAlertVisible && (
              <AlertMessage
                className={styles.alert}
                type={alertMessage.type}
                message={alertMessage.message}
                closeAlert={() => setIsAlertVisible(false)}
              />
            )}

            <div className={styles.sectionHeader}>Basic Info</div>
            <InputForm
              labelCol={{ span: 8 }}
              labelAlign="left"
              form={form}
              onValuesChange={handleValuesChange}
              onFinishFailed={handleSubmitFailed}
              onFinish={handleSubmit}
            >
              <Row gutter={10}>
                <Col span={23}>
                  <Form.Item
                    className={styles.guideName}
                    label="Benefits guide name"
                    name={GUIDE_NAME_FIELD}
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: EMPTY_MESSAGE,
                      },
                    ]}
                  >
                    <Input data-cy="guideName" />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <BoxPopover
                    header="Name of Benefit Guide"
                    description="This is the name of the guide that other admins will see. No employees will be able to see this name. You may want to include additional identifying terms such as the plan year, benefit class, or geographic location that this guide is for."
                  >
                    <QuestionCircleIcon className={styles.popoverIcon} />
                  </BoxPopover>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={23}>
                  <Form.Item
                    name={PLAN_YEAR_FIELD}
                    label="What plan year is this guide for?"
                    rules={[
                      { required: true, message: EMPTY_MESSAGE },
                      {
                        validator: async (rule, value) => {
                          if (
                            isPlanYearChangeValid(
                              value,
                              isEdit,
                              benguideData,
                              plansExist
                            )
                          ) {
                            setAlertMessage({
                              type: 'warning',
                              message:
                                BASIC_INFO_ERROR_MESSAGES.alertMessage.planYear,
                            });
                            setIsAlertVisible(true);
                            return Promise.reject(
                              new Error(
                                BASIC_INFO_ERROR_MESSAGES.planYear.fieldChange
                              )
                            );
                          }
                        },
                        warningOnly: true,
                      },
                    ]}
                  >
                    <Select
                      data-cy="planYear"
                      options={getOptionsFromPlanYears(
                        planYears,
                        benGuide?.planYearId ?? ''
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <BoxPopover
                    header="Plan Year and Benefits Guides"
                    description="Select which plan year this guide is for. You will only be able to add plans to this guide that share the same plan year."
                  >
                    <QuestionCircleIcon className={styles.popoverIcon} />
                  </BoxPopover>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={23}>
                  <Form.Item
                    label="What benefit classes is this guide for?"
                    name="benefitClasses"
                    rules={[
                      {
                        required: true,
                        message: EMPTY_MESSAGE,
                      },
                      ({ getFieldValue }) => ({
                        validator: async (rule, value) => {
                          if (
                            !isEmpty(value) &&
                            getFieldValue(PLAN_YEAR_FIELD) ===
                              benguideData?.planYearId &&
                            isBenefitClassChangeValid(
                              value,
                              isEdit,
                              benguideData,
                              plansExist
                            )
                          ) {
                            setAlertMessage({
                              type: 'warning',
                              message:
                                BASIC_INFO_ERROR_MESSAGES.alertMessage.planYear,
                            });
                            setIsAlertVisible(true);
                            return Promise.reject(
                              new Error(
                                BASIC_INFO_ERROR_MESSAGES.benefitClasses.fieldChange
                              )
                            );
                          }
                        },
                        warningOnly: true,
                      }),
                    ]}
                  >
                    <MultiSelectDropdown
                      dataCy="benefitClasses"
                      options={benefitClassOptions}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <BoxPopover
                    header="Benefit Classes and Benefits Guides"
                    description="Select which Benefit Class(es) this guide is for. You will only be able to add plans to this guide that are available to at least one of the benefit classes selected. If different classes have different employee contributions for certain plans, this will indicate which contributions the guide will display."
                  >
                    <QuestionCircleIcon className={styles.popoverIcon} />
                  </BoxPopover>
                </Col>
              </Row>
              <div className={styles.section}>
                <div>
                  <Form.Item
                    wrapperCol={{ span: 24 }}
                    className={styles.benefitTypeItem}
                    labelCol={{ span: 24 }}
                    label={
                      <div className={styles.sectionHeader}>
                        What Benefits Should Be In This Guide?
                        <BoxPopover
                          header="Included Benefits"
                          description="Select the benefits that should be included in this guide. You can change these at any time."
                          trigger="hover"
                        >
                          <QuestionCircleIcon className={styles.popoverIcon} />
                        </BoxPopover>
                      </div>
                    }
                    validateStatus={
                      selectedBenefits.length === 0 ? 'error' : 'validating'
                    }
                    help={
                      selectedBenefits.length === 0
                        ? BASIC_INFO_ERROR_MESSAGES.benefitTypes.empty
                        : ''
                    }
                    messageVariables={{ error: 'Error ' }}
                  >
                    <Checkbox.Group
                      data-cy="includedBenefits"
                      value={selectedBenefits}
                    >
                      <Row>
                        {Object.values(BENEFIT_TYPES).map(
                          ({ value, label, customTitleKey }, index) => {
                            return (
                              <Col span={6} key={index}>
                                <StyledCheckbox
                                  value={value}
                                  onChange={handleBenefitChange}
                                >
                                  {label}
                                </StyledCheckbox>
                                {showRenamePopover(customTitleKey)}
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </div>
              <div className={styles.sectionHeader}>
                What Additional Content Should Be In This Guide?
                {/* TODO: set link to sample guide */}
                <BoxPopover
                  header="Additional Content"
                  description={
                    <div className={styles.popoverContent}>
                      <div>
                        This additional content provides employees with key
                        benefits education as well as assistance in navigating
                        their benefits options. Anything that you select now can
                        be changed at any time.
                      </div>
                    </div>
                  }
                >
                  <QuestionCircleIcon className={styles.popoverIcon} />
                </BoxPopover>
              </div>
              <div>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Checkbox.Group
                    data-cy="additionalContent"
                    className={styles.formSection}
                    value={selectedContent}
                  >
                    <Row>
                      {Object.keys(ADDITIONAL_CONTENT_OPTIONS).map(
                        (key, index) => {
                          const { value, label, customTitleKey } =
                            ADDITIONAL_CONTENT_OPTIONS[
                              key as BasicInfoAdditionalContent
                            ];
                          return (
                            <Col span={6} key={index}>
                              <StyledCheckbox
                                value={value}
                                onChange={handleSelectedContentChange}
                              >
                                {label}
                              </StyledCheckbox>
                              {showRenamePopover(customTitleKey)}
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </div>
              <div className={styles.formActions}>
                <Button
                  type="default"
                  size="large"
                  data-cy="BasicInfoDBGCancel"
                  onClick={handleConfirmModalCancel}
                  disabled={
                    inProgress && requestType === saveBenGuideStarted.type
                  }
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="middle"
                  className={styles.saveBtn}
                  htmlType="submit"
                  data-cy="BasicInfoDBGSubmit"
                  loading={
                    inProgress && requestType === saveBenGuideStarted.type
                  }
                >
                  Save &amp; Next
                </Button>
              </div>
            </InputForm>
          </div>
          <ConfirmationDialog
            visible={isExitModalOpen}
            flipFooterOrder={!isEdit}
            onCancel={() => setIsExitModalOpen(false)}
            onOk={handleCloseBasicInfo}
            title={
              isEdit
                ? BASIC_INFO_EXIT_MESSAGES.editPage.title
                : BASIC_INFO_EXIT_MESSAGES.createPage.title
            }
            okText={
              isEdit
                ? BASIC_INFO_EXIT_MESSAGES.editPage.confirmText
                : BASIC_INFO_EXIT_MESSAGES.createPage.confirmText
            }
            cancelText={
              isEdit
                ? BASIC_INFO_EXIT_MESSAGES.editPage.cancelText
                : BASIC_INFO_EXIT_MESSAGES.createPage.cancelText
            }
          >
            <div>
              {isEdit
                ? BASIC_INFO_EXIT_MESSAGES.editPage.message
                : BASIC_INFO_EXIT_MESSAGES.createPage.message}
            </div>
          </ConfirmationDialog>
          <ConfirmationDialog
            visible={isConfirmModalOpen}
            onCancel={() => {
              setIsConfirmModalOpen(false);
              setValueToDisable(null);
            }}
            cancelText="Cancel"
            flipFooterOrder
            okText={
              valueToDisable
                ? `Yes - Remove ${valueToDisable.label}`
                : 'Yes - Remove Section'
            }
            onOk={() => onConfirmDisable(valueToDisable)}
            title={valueToDisable ? `Remove ${valueToDisable.label}` : 'Remove'}
          >
            {isValueToDisableBenefit
              ? REMOVE_BENEFIT_MODAL_MESSAGE
              : REMOVE_CONTENT_MODAL_MESSAGE}
          </ConfirmationDialog>

          <ConfirmationDialog
            visible={isRegenerateModalOpen}
            onCancel={() => {
              cancelRegenerateNHC();
            }}
            cancelText={UPDATE_NHC_CANCEL_TEXT}
            flipFooterOrder
            okText={UPDATE_NHC_CONFIRMATION_TEXT}
            onOk={() => regenerateNHC()}
            title="Update New Hire Checklist?"
          >
            <>
              <p className={styles.nhcUpdateText}>
                {UPDATE_NHC_MODAL_DESCRIPTION}
              </p>
              <br />
              {NHC_REGENERATE_WARNING_MSG}
            </>
          </ConfirmationDialog>
        </div>
      )}
    </div>
  );
};

export default BasicInfo;
