import { FC, useEffect, useRef, useState } from 'react';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { useAppDispatch } from 'hooks/redux';

import {
  createRichMediaOverview,
  getRichMediaOverview,
} from 'modules/home/slices/homeSlice';
import { SectionName } from 'modules/home/constants';
import { PLAN_DESCRIPTION_WORD_LIMIT } from 'modules/benefitsWellness/constants/BenefitConstants';

import styles from './overviewEditor.module.less';

type OverviewEditorProps = {
  isOpenModal: boolean;
  closeModal: Function;
  textWdith: number;
  editorContent: string;
  updateEditorContent: (data: string) => void;
  resetToDefault: () => void;
  isResetEnabled: boolean;
  benguideData?: any; // Benguide object might differ
  defaultTextOverview: string;
  resetCurrentValue: Function;
  title: string;
};

const OverviewEditor: FC<OverviewEditorProps> = (
  props: OverviewEditorProps
) => {
  const {
    isOpenModal,
    closeModal,
    textWdith,
    editorContent,
    updateEditorContent,
    resetToDefault,
    isResetEnabled,
    benguideData,
    defaultTextOverview,
    resetCurrentValue,
    title: _title,
  } = props;
  const { masterId = '', revision } = benguideData || {};
  const textEditorRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>(_title);

  useEffect(() => {
    setTitle(_title);
  }, [_title]);
  const resetContent = () => {
    const { current } = textEditorRef;
    const { clearTextEditorContent } = current;
    setTitle(_title);
    clearTextEditorContent(defaultTextOverview);
    resetToDefault();
  };

  const closeOverviewModal = () => {
    resetCurrentValue();
    if (textEditorRef.current) {
      textEditorRef.current.clearTextEditorContent();
    }
    dispatch(getRichMediaOverview(masterId));
    closeModal();
  };

  const handleEditorChange = (data: string) => {
    updateEditorContent(data);
  };

  const handleSubmit = () => {
    dispatch(
      createRichMediaOverview(
        masterId,
        SectionName.HOME_OVERVIEW_TEXT,
        editorContent,
        title
      )
    );
    closeModal();
  };

  useEffect(() => {
    const { current } = textEditorRef;
    const { changeEditorContent } = current;
    changeEditorContent(editorContent);
  }, [editorContent]);

  return (
    <HalfScreenModal
      width={`${textWdith + 88}px`}
      title={title}
      visible={isOpenModal}
      onReset={resetContent}
      onCancel={closeOverviewModal}
      onSubmit={handleSubmit}
      confirmModalShow={true}
      isResetEnabled={isResetEnabled}
      footerStyles={styles.footerOverviewEditor}
      editableTitle={true}
      onChangeTitle={setTitle}
      destroyOnClose={true}
    >
      <RichTextEditor
        limit={PLAN_DESCRIPTION_WORD_LIMIT}
        initialValue={editorContent}
        onChange={handleEditorChange}
        defaultText={editorContent}
        ref={textEditorRef}
        masterId={masterId}
        revision={revision}
      />
    </HalfScreenModal>
  );
};

export default OverviewEditor;
