// Use this hook to check if the content of a ref element is overflowing its container.
import { useState, useEffect, RefObject } from 'react';

export function useOverflow(ref: RefObject<HTMLDivElement>) {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const element = ref.current;
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [ref]);

  return isOverflowing;
}
