import { useState, useEffect, useRef, useCallback } from 'react';

import { Col, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';
import { useGetCustomNavTitle } from 'hooks/useGetCustomNavTitle';
import { RouteKeyConstants } from 'constants/routeKeyConstants';
import PageHeader from 'components/PageHeader/PageHeader';
import DatesEditable from 'modules/datesEligability/components/DatesEditable/DatesEditable';
import PageSection from 'model/PageSection';
import { getPlanYearById } from 'modules/home/slices/basicInfoSlice';
import {
  createMultipleRichMedia,
  getCurrentLifeRichMedia,
  getCurrentOERichMedia,
  getCurrentRulesRichMedia,
  getCurrentWaiverRichMedia,
  getDatesEligibilitySection,
  getDefaultLifeRichMedia,
  getDefaultOERichMedia,
  getDefaultRulesRichMedia,
  getDefaultWaiverRichMedia,
} from 'modules/datesEligability/slices/datesSlice';
import { formatPlanYear } from 'utils/planYearUtils';
import { formatDateWithoutTime } from 'utils/dateUtil';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import { usePrevious } from 'hooks/usePrevious';

import { BenGuideStatus } from 'constants/commonConstants';
import { SectionName } from 'modules/home/constants';
import { DATES_SECTIONS } from 'modules/datesEligability/constants';

import { ReactComponent as DatesIcon } from 'images/pageHeaderIcons/icon-dates.svg';
import { ReactComponent as OpenEnrollmentIcon } from 'images/icon-open-enrollment.svg';
import styles from './datesEligability.module.less';

type DatesSection = {
  code: string;
  content: string;
  isUpdated: boolean;
};

const defaultSectionData = DATES_SECTIONS.map((section) => ({
  code: section.code,
  content: '',
  isUpdated: false,
}));

const DatesEligability = () => {
  const textRef = useRef<HTMLDivElement>(null);
  const textEditorRef = useRef<any>(null);
  const sectionEditorRef = useRef<any>(null);

  const dispatch = useAppDispatch();
  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide;
  const { masterId, planYearId, employerId, status } = benguideData || {};
  const { revision } = useBenguide();
  const { benefitGuide } = useAppSelector((state) => state.basicInfo);
  const { planYear } = useAppSelector((state) => state.basicInfo);

  const {
    defaultOEContent,
    defaultRulesContent,
    defaultWaiverContent,
    defaultLifeContent,
    currentOEContent,
    currentRulesContent,
    currentLifeContent,
    currentWaiverContent,
    createRichMediaSuccess,
    latestRevision,
    sections,
  } = useAppSelector((state) => state.datesEligibility);
  const { isEditMode } = useBenguide();

  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [datesSectionLabel, setDatesSectionLabel] = useState<string>('');
  const [enableOE, setEnableOE] = useState<boolean>(true);
  const [enableRules, setEnableRules] = useState<boolean>(true);
  const [enableLifeEvents, setEnableLifeEvents] = useState<boolean>(true);
  const [enableWaiver, setEnableWaiver] = useState<boolean>(true);
  const [displayOEData, setDisplayOEData] = useState<string>('');
  const [displayRulesData, setDisplayRulesData] = useState<string>('');
  const [displayLifeData, setDisplayLifeData] = useState<string>('');
  const [displayWaiverData, setDisplayWaiverData] = useState<string>('');
  const [sectionOrder, setSectionOrder] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>();
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>();
  const [checkDisableButton, setCheckDisableButton] = useState<boolean>(true);
  const [savedContentData, setSavedContentData] =
    useState<DatesSection[]>(defaultSectionData);
  const [enabledSections, setEnabledSections] = useState<string[]>();
  const previousCreateRichMediaSuccess = usePrevious(createRichMediaSuccess);

  const [savedWaiverAmount, setSavedWaiverAmount] = useState<string>();
  const [savedWaiverFrequency, setSavedWaiverFrequency] =
    useState<string>('Month');
  const [waiverAmount, setWaiverAmount] = useState<string>();
  const [waiverFrequency, setWaiverFrequency] = useState<string>('Month');
  const sectionWaiverAmount = sections?.data[1]?.data?.amount;
  const sectionWaiverFrequency = sections?.data[1]?.data?.frequency;

  const customNavigationTitles = useGetCustomNavTitle({
    key: RouteKeyConstants.DATES_AND_ELIGIBILITY as keyof typeof RouteKeyConstants,
  });

  const init = useCallback(() => {
    dispatch(getDefaultOERichMedia(masterId, SectionName.DE_OE_TEXT));
    dispatch(getCurrentOERichMedia(masterId, SectionName.DE_OE_TEXT));
    dispatch(getDefaultRulesRichMedia(masterId, SectionName.DE_RULES_TEXT));
    dispatch(getCurrentRulesRichMedia(masterId, SectionName.DE_RULES_TEXT));
    dispatch(
      getDefaultLifeRichMedia(masterId, SectionName.DE_LIFE_EVENTS_TEXT)
    );
    dispatch(
      getCurrentLifeRichMedia(masterId, SectionName.DE_LIFE_EVENTS_TEXT)
    );
    dispatch(getDefaultWaiverRichMedia(masterId, SectionName.DE_WAIVER_TEXT));
    dispatch(getCurrentWaiverRichMedia(masterId, SectionName.DE_WAIVER_TEXT));
  }, [masterId, dispatch]);

  useEffect(() => {
    if (planYearId && employerId) {
      dispatch(getPlanYearById(employerId, planYearId));
    }
  }, [dispatch, employerId, planYearId]);

  useEffect(() => {
    if (!previousCreateRichMediaSuccess && createRichMediaSuccess) {
      dispatch(getDatesEligibilitySection(masterId));
    }
  }, [
    dispatch,
    masterId,
    previousCreateRichMediaSuccess,
    createRichMediaSuccess,
    revision,
  ]);

  useEffect(() => {
    setEnabledSections(
      sections.data
        .filter((section) => section.enabled)
        .map((section) => section.name)
    );
    if (status !== BenGuideStatus.PUBLISHED) {
      if (!isEditMode) {
        init();
      } else if (createRichMediaSuccess) {
        init();
      }
    } else {
      init();
    }
  }, [
    sections?.data,
    createRichMediaSuccess,
    init,
    isEditMode,
    latestRevision,
    revision,
    status,
  ]);

  useEffect(() => {
    if (sections?.data && isEmpty(sectionOrder) && checkDisableButton) {
      sections?.data.forEach((section: PageSection) => {
        const sectionArray = sectionOrder;
        if (!section.enabled) {
          sectionArray.push(section.name);
        }
        setSectionOrder(sectionArray);

        if (section.name === DATES_SECTIONS[0].code) {
          setEnableOE(section.enabled);
          if (section.data && section.data.startDate && section.data.endDate) {
            setStartDate(dayjs(section.data.startDate));
            setEndDate(dayjs(section.data.endDate));
          }
        } else if (section.name === DATES_SECTIONS[1].code) {
          setEnableRules(section.enabled);
        } else if (section.name === DATES_SECTIONS[2].code) {
          setEnableLifeEvents(section.enabled);
        } else {
          setEnableWaiver(section.enabled);
          if (
            !!(section?.data?.amount !== null) &&
            !!section?.data?.frequency
          ) {
            setWaiverAmount(section.data.amount);
            setWaiverFrequency(section.data.frequency);
            setSavedWaiverAmount(section.data.amount);
            setSavedWaiverFrequency(section.data.frequency);
          }
        }
      });
    }
  }, [benefitGuide, checkDisableButton, sectionOrder, sections?.data]);

  useEffect(() => {
    if (!isEmpty(defaultOEContent)) {
      if (
        defaultOEContent.localeCompare(currentOEContent) === 0 ||
        currentOEContent === null ||
        currentOEContent === undefined
      ) {
        setDisplayOEData(defaultOEContent);
      } else {
        setDisplayOEData(currentOEContent);
      }
    }
    if (!isEmpty(defaultRulesContent)) {
      if (
        defaultRulesContent.localeCompare(currentRulesContent) === 0 ||
        currentRulesContent === null ||
        currentRulesContent === undefined
      ) {
        setDisplayRulesData(defaultRulesContent);
      } else {
        setDisplayRulesData(currentRulesContent);
      }
    }

    if (!isEmpty(defaultLifeContent)) {
      if (
        defaultOEContent.localeCompare(currentLifeContent) === 0 ||
        currentLifeContent === null ||
        currentLifeContent === undefined
      ) {
        setDisplayLifeData(defaultLifeContent);
      } else {
        setDisplayLifeData(currentLifeContent);
      }
    }
    if (!isEmpty(defaultWaiverContent)) {
      if (
        defaultOEContent.localeCompare(currentWaiverContent) === 0 ||
        currentWaiverContent === null ||
        currentWaiverContent === undefined
      ) {
        setDisplayWaiverData(defaultWaiverContent);
      } else {
        setDisplayWaiverData(currentWaiverContent);
      }
    }
  }, [
    currentLifeContent,
    currentOEContent,
    currentRulesContent,
    currentWaiverContent,
    defaultLifeContent,
    defaultOEContent,
    defaultRulesContent,
    defaultWaiverContent,
  ]);

  const onChangeSection = (value: string) => {
    DATES_SECTIONS.forEach((dates) => {
      if (dates.code === value) {
        setDatesSectionLabel(dates.title);
      }
    });
  };

  const onchangeDate = (value: dayjs.Dayjs | null, isStartDate: boolean) => {
    if (isStartDate) {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const onSwitch = (value: boolean, selectedOption: string) => {
    if (selectedOption === DATES_SECTIONS[0].code) {
      setEnableOE(value);
    } else if (selectedOption === DATES_SECTIONS[1].code) {
      setEnableRules(value);
    } else if (selectedOption === DATES_SECTIONS[2].code) {
      setEnableLifeEvents(value);
    } else {
      setEnableWaiver(value);
    }

    setSavedContentData(
      savedContentData.map((savedContent) =>
        savedContent.code === selectedOption
          ? {
              ...savedContent,
              isUpdated: true,
            }
          : savedContent
      )
    );
  };

  const resetSwitches = () => {
    setEnableOE(enabledSections?.includes(SectionName.DE_OE_TEXT) || false);
    setEnableRules(
      enabledSections?.includes(SectionName.DE_RULES_TEXT) || false
    );
    setEnableLifeEvents(
      enabledSections?.includes(SectionName.DE_LIFE_EVENTS_TEXT) || false
    );
    setEnableWaiver(
      enabledSections?.includes(SectionName.DE_WAIVER_TEXT) || false
    );
  };

  const resetToDefault = (selectedOption: string) => {
    if (selectedOption === DATES_SECTIONS[0].code) {
      setDisplayOEData(defaultOEContent);
      textEditorRef.current.clearTextEditorContent(defaultOEContent);
    } else if (selectedOption === DATES_SECTIONS[1].code) {
      setDisplayRulesData(defaultRulesContent);
      textEditorRef.current.clearTextEditorContent(defaultRulesContent);
    } else if (selectedOption === DATES_SECTIONS[2].code) {
      setDisplayLifeData(defaultLifeContent);
      textEditorRef.current.clearTextEditorContent(defaultLifeContent);
    } else {
      setDisplayWaiverData(defaultWaiverContent);
      textEditorRef.current.clearTextEditorContent(defaultWaiverContent);
    }

    const currentSavedData = savedContentData;
    const updatedSavedData = currentSavedData.map((savedContent) =>
      savedContent.code === selectedOption
        ? {
            ...savedContent,
            content: '',
            isUpdated: true,
          }
        : savedContent
    );
    setSavedContentData(updatedSavedData);
  };

  const closeSupportModal = (onClose: Function, option: string) => {
    if (textEditorRef.current) {
      if (option === DATES_SECTIONS[0].code) {
        textEditorRef.current.clearTextEditorContent(displayOEData);
      } else if (option === DATES_SECTIONS[1].code) {
        textEditorRef.current.clearTextEditorContent(displayRulesData);
      } else if (option === DATES_SECTIONS[2].code) {
        textEditorRef.current.clearTextEditorContent(displayLifeData);
      } else {
        textEditorRef.current.clearTextEditorContent(displayWaiverData);
      }
    }
    if (sectionEditorRef.current) {
      sectionEditorRef.current.resetSelectedOption();
    }

    setDisplayOEData(currentOEContent);
    setDisplayRulesData(currentRulesContent);
    setDisplayLifeData(currentLifeContent);
    setDisplayWaiverData(currentWaiverContent);
    setWaiverAmount(savedWaiverAmount);
    setWaiverFrequency(savedWaiverFrequency);

    onChangeSection(option);
    setSavedContentData(defaultSectionData);
    resetSwitches();
    onClose();
  };

  const saveAllContent = (option: string, content: string) => {
    const currentSavedData = savedContentData;
    const updatedSavedData = currentSavedData.map((savedData) =>
      savedData.code === option
        ? {
            ...savedData,
            content: content,
            isUpdated: true,
          }
        : savedData
    );
    setSavedContentData(updatedSavedData);
  };

  const onSubmit = (
    closeModal: Function,
    selectedOption: string,
    isHiddenEditor: boolean
  ) => {
    const sectionArray = sectionOrder;
    if (selectedOption === DATES_SECTIONS[0].code) {
      saveUpdatedData();
      setSavedWaiverAmount(waiverAmount);
      setSavedWaiverFrequency(waiverFrequency);
      if (!enableOE) {
        sectionArray.push(selectedOption);
      } else if (
        !isEmpty(sectionArray) &&
        sectionOrder.includes(selectedOption)
      ) {
        sectionArray.pop();
      }
    } else if (selectedOption === DATES_SECTIONS[1].code) {
      saveUpdatedData();
      setSavedWaiverAmount(waiverAmount);
      setSavedWaiverFrequency(waiverFrequency);
      if (!enableRules) {
        sectionArray.push(selectedOption);
      } else if (
        !isEmpty(sectionArray) &&
        sectionOrder.includes(selectedOption)
      ) {
        sectionArray.pop();
      }
    } else if (selectedOption === DATES_SECTIONS[2].code) {
      saveUpdatedData();
      setSavedWaiverAmount(waiverAmount);
      setSavedWaiverFrequency(waiverFrequency);
      if (!enableLifeEvents) {
        sectionArray.push(selectedOption);
      } else if (
        !isEmpty(sectionArray) &&
        sectionOrder.includes(selectedOption)
      ) {
        sectionArray.pop();
      }
    } else {
      saveUpdatedData();
      setSavedWaiverAmount(waiverAmount);
      setSavedWaiverFrequency(waiverFrequency);
      if (!enableWaiver) {
        sectionArray.push(selectedOption);
      } else if (
        !isEmpty(sectionArray) &&
        sectionOrder.includes(selectedOption)
      ) {
        sectionArray.pop();
      }
    }
    if (isEmpty(sectionArray)) {
      setCheckDisableButton(false);
    }

    if (isHiddenEditor && !isEmpty(sectionArray)) {
      onChangeSection(sectionArray[sectionArray.length - 1]);
    } else {
      onChangeSection(selectedOption);
    }

    if (sectionEditorRef.current && !isHiddenEditor) {
      sectionEditorRef.current.resetSelectedOption();
    }

    setSectionOrder(sectionArray);
    setSavedContentData(defaultSectionData);
    closeModal();
  };

  const saveUpdatedData = async () => {
    if (masterId) {
      const updatedData = savedContentData
        .filter((section) => section.isUpdated)
        .map((section) => {
          return {
            sectionName: section.code,
            content: section.content,
            title: section.code,
            enabled: getToggleValue(section.code),
            waiverAmount:
              section.code === DATES_SECTIONS[3].code ? waiverAmount : '',
            waiverFrequency:
              section.code === DATES_SECTIONS[3].code ? waiverFrequency : '',
            startDate:
              section.code === DATES_SECTIONS[0].code
                ? formatDateWithoutTime(startDate?.toString())
                : '',
            endDate:
              section.code === DATES_SECTIONS[0].code
                ? formatDateWithoutTime(endDate?.toString())
                : '',
          };
        });

      dispatch(createMultipleRichMedia(masterId, updatedData));
    }
  };

  const getToggleValue = (option: string) => {
    if (option === DATES_SECTIONS[0].code) {
      return enableOE;
    } else if (option === DATES_SECTIONS[1].code) {
      return enableRules;
    } else if (option === DATES_SECTIONS[2].code) {
      return enableLifeEvents;
    } else {
      return enableWaiver;
    }
  };

  const getLatestDisabledData = (
    latestSection: string,
    isDisplayData: boolean
  ) => {
    if (latestSection === DATES_SECTIONS[0].code) {
      if (isDisplayData) {
        return displayOEData;
      } else {
        return DATES_SECTIONS[0].title;
      }
    } else if (latestSection === DATES_SECTIONS[1].code) {
      if (isDisplayData) {
        return displayRulesData;
      } else {
        return DATES_SECTIONS[1].title;
      }
    } else if (latestSection === DATES_SECTIONS[2].code) {
      if (isDisplayData) {
        return displayLifeData;
      } else {
        return DATES_SECTIONS[2].title;
      }
    } else {
      if (isDisplayData) {
        return displayWaiverData;
      } else {
        return DATES_SECTIONS[3].title;
      }
    }
  };

  const label = getLatestDisabledData(
    sectionOrder[sectionOrder.length - 1],
    false
  );

  const getNextEnableSection = () => {
    if (!enableOE) {
      return SectionName.DE_OE_TEXT;
    } else if (!enableRules) {
      return SectionName.DE_RULES_TEXT;
    } else if (!enableLifeEvents) {
      return SectionName.DE_LIFE_EVENTS_TEXT;
    } else if (!enableWaiver) {
      return SectionName.DE_WAIVER_TEXT;
    }
  };

  const onChangeWaiverAmount = (value: string) => {
    setWaiverAmount(value.replaceAll(',', ''));
  };

  const onChangeWaiverFrequency = (value: string) => {
    setWaiverFrequency(value);
  };

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      <div className={styles.datesWrapper} ref={textRef}>
        <BenefitGuideRefreshChannel />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <div>
          <PageHeader
            pageHeaderTitle={customNavigationTitles}
            pageHeaderIcon={<DatesIcon className={styles.datesIcon} />}
            customIconStyle={styles.importantDates}
          />
          <div>
            <div className={styles.datesHeadingRow}>
              <span className={styles.datesHeading}>Important Dates</span>
            </div>
            <Row className={styles.openEnrollmentIcon}>
              <Col>
                <OpenEnrollmentIcon className={styles.oeIcon} />
              </Col>
              <Col className={styles.effectiveDate}>
                <Row className={styles.effectiveLabel}>
                  Plan Effective Dates:
                </Row>
                <Row className={styles.planYear}>
                  {planYear && formatPlanYear(planYear)}
                </Row>
              </Col>
            </Row>
            {enabledSections?.includes(SectionName.DE_OE_TEXT) && (
              <div className={styles.editableOE}>
                <DatesEditable
                  savedData={savedContentData}
                  previewContent={currentOEContent}
                  closeSupportModal={closeSupportModal}
                  onSubmit={onSubmit}
                  onChangeSection={(section: string) =>
                    onChangeSection(section)
                  }
                  onSwitch={(enabled: boolean, selectedOption: string) =>
                    onSwitch(enabled, selectedOption)
                  }
                  textEditorRef={textEditorRef}
                  sectionEditorRef={sectionEditorRef}
                  enabled={getToggleValue}
                  displayData={displayOEData}
                  datesSectionLabel={datesSectionLabel}
                  masterId={masterId}
                  editableSectionName={DATES_SECTIONS[0].code}
                  editableSectionTitle={DATES_SECTIONS[0].title}
                  onchangeDate={onchangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  displayOEData={displayOEData}
                  displayLifeData={displayLifeData}
                  displayRulesData={displayRulesData}
                  displayWaiverData={displayWaiverData}
                  resetToDefault={(selectedOption: string) =>
                    resetToDefault(selectedOption)
                  }
                  waiverAmount={waiverAmount}
                  waiverFrequency={waiverFrequency}
                  onChangeWaiverAmount={onChangeWaiverAmount}
                  onChangeWaiverFrequency={onChangeWaiverFrequency}
                  saveAllContent={saveAllContent}
                  sectionWaiverAmount={sectionWaiverAmount}
                  sectionWaiverFrequency={sectionWaiverFrequency}
                />
              </div>
            )}
          </div>
        </div>
        <div className={enableOE ? styles.eligibility : ''}>
          {(enabledSections?.includes(SectionName.DE_RULES_TEXT) ||
            enabledSections?.includes(SectionName.DE_LIFE_EVENTS_TEXT) ||
            enabledSections?.includes(SectionName.DE_WAIVER_TEXT)) && (
            <div className={styles.datesHeadingRow}>
              <span className={styles.datesHeading}>Eligibility</span>
            </div>
          )}
          {enabledSections?.includes(SectionName.DE_RULES_TEXT) && (
            <div className={styles.editableBorder}>
              <span className={styles.heading}>Rules</span>
              <DatesEditable
                savedData={savedContentData}
                previewContent={currentRulesContent}
                closeSupportModal={closeSupportModal}
                onSubmit={onSubmit}
                onChangeSection={(section: string) => onChangeSection(section)}
                onSwitch={(enabled: boolean, selectedOption: string) =>
                  onSwitch(enabled, selectedOption)
                }
                textEditorRef={textEditorRef}
                sectionEditorRef={sectionEditorRef}
                enabled={getToggleValue}
                displayData={displayRulesData}
                datesSectionLabel={datesSectionLabel}
                masterId={masterId}
                editableSectionName={DATES_SECTIONS[1].code}
                editableSectionTitle={DATES_SECTIONS[1].title}
                displayOEData={displayOEData}
                displayLifeData={displayLifeData}
                displayRulesData={displayRulesData}
                displayWaiverData={displayWaiverData}
                resetToDefault={(selectedOption: string) =>
                  resetToDefault(selectedOption)
                }
                startDate={startDate}
                endDate={endDate}
                onchangeDate={onchangeDate}
                waiverAmount={waiverAmount}
                waiverFrequency={waiverFrequency}
                onChangeWaiverAmount={onChangeWaiverAmount}
                onChangeWaiverFrequency={onChangeWaiverFrequency}
                saveAllContent={saveAllContent}
                sectionWaiverAmount={sectionWaiverAmount}
                sectionWaiverFrequency={sectionWaiverFrequency}
              />
            </div>
          )}
          {enabledSections?.includes(SectionName.DE_LIFE_EVENTS_TEXT) && (
            <div className={styles.editableBorder}>
              <span className={styles.heading}>Qualifying Life Events</span>
              <DatesEditable
                savedData={savedContentData}
                previewContent={currentLifeContent}
                closeSupportModal={closeSupportModal}
                onSubmit={onSubmit}
                onChangeSection={(section: string) => onChangeSection(section)}
                onSwitch={(enabled: boolean, selectedOption: string) =>
                  onSwitch(enabled, selectedOption)
                }
                textEditorRef={textEditorRef}
                sectionEditorRef={sectionEditorRef}
                enabled={getToggleValue}
                displayData={displayLifeData}
                datesSectionLabel={datesSectionLabel}
                masterId={masterId}
                editableSectionName={DATES_SECTIONS[2].code}
                editableSectionTitle={DATES_SECTIONS[2].title}
                displayOEData={displayOEData}
                displayLifeData={displayLifeData}
                displayRulesData={displayRulesData}
                displayWaiverData={displayWaiverData}
                resetToDefault={(selectedOption: string) =>
                  resetToDefault(selectedOption)
                }
                startDate={startDate}
                endDate={endDate}
                onchangeDate={onchangeDate}
                waiverAmount={waiverAmount}
                waiverFrequency={waiverFrequency}
                onChangeWaiverAmount={onChangeWaiverAmount}
                onChangeWaiverFrequency={onChangeWaiverFrequency}
                saveAllContent={saveAllContent}
                sectionWaiverAmount={sectionWaiverAmount}
                sectionWaiverFrequency={sectionWaiverFrequency}
              />
            </div>
          )}
          {enabledSections?.includes(SectionName.DE_WAIVER_TEXT) && (
            <div className={styles.editableBorder}>
              <span className={styles.heading}>Waiver Credit</span>
              <DatesEditable
                savedData={savedContentData}
                previewContent={currentWaiverContent}
                closeSupportModal={closeSupportModal}
                onSubmit={onSubmit}
                onChangeSection={(section: string) => onChangeSection(section)}
                onSwitch={(enabled: boolean, selectedOption: string) =>
                  onSwitch(enabled, selectedOption)
                }
                textEditorRef={textEditorRef}
                sectionEditorRef={sectionEditorRef}
                enabled={getToggleValue}
                displayData={displayWaiverData}
                datesSectionLabel={datesSectionLabel}
                masterId={masterId}
                editableSectionName={DATES_SECTIONS[3].code}
                editableSectionTitle={DATES_SECTIONS[3].title}
                displayOEData={displayOEData}
                displayLifeData={displayLifeData}
                displayRulesData={displayRulesData}
                displayWaiverData={displayWaiverData}
                resetToDefault={(selectedOption: string) =>
                  resetToDefault(selectedOption)
                }
                startDate={startDate}
                endDate={endDate}
                onchangeDate={onchangeDate}
                saveAllContent={saveAllContent}
                waiverAmount={waiverAmount}
                waiverFrequency={waiverFrequency}
                onChangeWaiverAmount={onChangeWaiverAmount}
                onChangeWaiverFrequency={onChangeWaiverFrequency}
                sectionWaiverAmount={sectionWaiverAmount}
                sectionWaiverFrequency={sectionWaiverFrequency}
              />
            </div>
          )}
          {(enabledSections || []).length < 4 && (
            <div className={styles.hotspotHidden}>
              <DatesEditable
                savedData={savedContentData}
                closeSupportModal={closeSupportModal}
                onSubmit={onSubmit}
                onChangeSection={(section: string) => onChangeSection(section)}
                onSwitch={(enabled: boolean, selectedOption: string) =>
                  onSwitch(enabled, selectedOption)
                }
                textEditorRef={textEditorRef}
                sectionEditorRef={sectionEditorRef}
                enabled={(option: string) => getToggleValue(option)}
                displayData={getLatestDisabledData(
                  sectionOrder[sectionOrder.length - 1],
                  true
                )}
                datesSectionLabel={
                  label !== datesSectionLabel ? datesSectionLabel : label
                }
                masterId={masterId}
                editableSectionName={sectionOrder[sectionOrder.length - 1]}
                editableSectionTitle={getLatestDisabledData(
                  sectionOrder[sectionOrder.length - 1],
                  false
                )}
                displayOEData={displayOEData}
                displayLifeData={displayLifeData}
                displayRulesData={displayRulesData}
                displayWaiverData={displayWaiverData}
                resetToDefault={(selectedOption: string) =>
                  resetToDefault(selectedOption)
                }
                startDate={startDate}
                endDate={endDate}
                isSectionDisabled={true}
                isHiddenEditor={true}
                saveAllContent={saveAllContent}
                nextEnableSection={getNextEnableSection()}
                onChangeWaiverAmount={onChangeWaiverAmount}
                onChangeWaiverFrequency={onChangeWaiverFrequency}
                waiverAmount={waiverAmount}
                waiverFrequency={waiverFrequency}
                sectionWaiverAmount={sectionWaiverAmount}
                sectionWaiverFrequency={sectionWaiverFrequency}
              />
            </div>
          )}
        </div>
      </div>
    </NotificationContextProvider>
  );
};

export default DatesEligability;
