import { FC } from 'react';

import { Popover } from 'antd';
import { useGetCustomNavTitle } from 'hooks/useGetCustomNavTitle';

import { RouteKeyConstants } from 'constants/routeKeyConstants';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';
import { ReactComponent as MedicalIcon } from 'images/icons/side-nav/medical.svg';
import { ReactComponent as DentalIcon } from 'images/icons/side-nav/dental.svg';

import { ReactComponent as VisionIcon } from 'images/icons/side-nav/vision.svg';
import styles from './mdvPlanTitle.module.less';

type MDVPlanTitleProps = {
  planType: MDVTypes;
  addedPlansCount: number;
};

const MDVPlanTitle: FC<MDVPlanTitleProps> = (props: MDVPlanTitleProps) => {
  const { planType, addedPlansCount } = props;

  const customNavigationTitles = useGetCustomNavTitle({
    key: RouteKeyConstants[
      planType.toUpperCase() as keyof typeof MDVTypes
    ] as keyof typeof RouteKeyConstants,
  });

  const showPlanTypeIcon = () => {
    if (planType === MDVTypes.MEDICAL) {
      return <MedicalIcon className={styles.greyIcon} />;
    } else if (planType === MDVTypes.DENTAL) {
      return <DentalIcon className={styles.greyIcon} />;
    } else {
      return <VisionIcon className={styles.greyIcon} />;
    }
  };
  return (
    <div className={styles.mdvPlanTitleWrapper}>
      <div className={styles.icon}>{showPlanTypeIcon()}</div>
      <Popover
        content={
          <div>
            {`${customNavigationTitles}`}
            <span className={styles.mdvCount}>({addedPlansCount}) </span>
          </div>
        }
        placement="top"
        overlayClassName={`popoverInner ${styles.planWrapper}`}
      >
        <div className={styles.title}>
          {`${customNavigationTitles}`}
          <span className={styles.mdvCount}>({addedPlansCount}) </span>
        </div>
      </Popover>
    </div>
  );
};

export default MDVPlanTitle;
