import { useAppSelector } from 'hooks/redux';
import {
  defaultRouteTitles,
  RouteKeyConstants,
} from 'constants/routeKeyConstants';

type Props = {
  key: keyof typeof RouteKeyConstants | null;
};

export const useGetCustomNavTitle = (props: Props): string => {
  const { key } = props;
  const benguideState = useAppSelector((state) => state.benguide);
  const { customNavigationTitles } = benguideState;

  const customNavTitle = customNavigationTitles?.data?.[key!]
    ? customNavigationTitles?.data[key!]
    : defaultRouteTitles[key!];

  return customNavTitle || '';
};
