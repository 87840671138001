import RouteMap from 'routes/RouteMap';
import { ReactComponent as MedicalIcon } from 'images/icons/side-nav/medical.svg';
import { ReactComponent as TelehealthIcon } from 'images/icons/side-nav/telehealth.svg';
import { ReactComponent as DentalIcon } from 'images/icons/side-nav/dental.svg';
import { ReactComponent as VisionIcon } from 'images/icons/side-nav/vision.svg';
import { ReactComponent as WellbeingIcon } from 'images/icons/side-nav/wellbeing.svg';
import { ReactComponent as LifeDisabilityIcon } from 'images/icons/side-nav/life-and-disability.svg';
import { ReactComponent as VoluntaryIcon } from 'images/icons/side-nav/voluntary.svg';
import { ReactComponent as TaxAdvantagedIcon } from 'images/icons/side-nav/tax-advantaged.svg';
import { ReactComponent as CommuterIcon } from 'images/icons/side-nav/commuter.svg';
import {
  defaultRouteTitles,
  RouteKeyConstants,
} from 'constants/routeKeyConstants';

export const sideNavRoutes = [
  {
    key: RouteKeyConstants.HOME,
    path: RouteMap.HOME_PATH,
    label: defaultRouteTitles[RouteKeyConstants.HOME],
  },

  {
    key: RouteKeyConstants.NEW_HIRE_CHECKLIST,
    path: RouteMap.NEW_HIRE_CHECKLIST,
    label: defaultRouteTitles[RouteKeyConstants.NEW_HIRE_CHECKLIST],
  },
  {
    key: RouteKeyConstants.HOW_INSURANCE_WORKS,
    path: RouteMap.HOW_INSURANCE_WORKS,
    label: defaultRouteTitles[RouteKeyConstants.HOW_INSURANCE_WORKS],
  },
  {
    key: RouteKeyConstants.DATES_AND_ELIGIBILITY,
    path: RouteMap.DATES_AND_ELIGIBILITY,
    label: defaultRouteTitles[RouteKeyConstants.DATES_AND_ELIGIBILITY],
  },
  {
    key: RouteKeyConstants.BENEFITS_WELLNESS,
    path: 'health-benefit/',
    label: defaultRouteTitles[RouteKeyConstants.BENEFITS_WELLNESS],
    children: [
      {
        key: RouteKeyConstants.MEDICAL,
        path: RouteMap.MEDICAL,
        label: defaultRouteTitles[RouteKeyConstants.MEDICAL],
        icon: <MedicalIcon />,
      },
      {
        key: RouteKeyConstants.TELEHEALTH,
        path: RouteMap.TELEHEALTH,
        label: defaultRouteTitles[RouteKeyConstants.TELEHEALTH],
        icon: <TelehealthIcon />,
      },
      {
        key: RouteKeyConstants.DENTAL,
        path: RouteMap.DENTAL,
        label: defaultRouteTitles[RouteKeyConstants.DENTAL],
        icon: <DentalIcon />,
      },
      {
        key: RouteKeyConstants.VISION,
        path: RouteMap.VISION,
        label: defaultRouteTitles[RouteKeyConstants.VISION],
        icon: <VisionIcon />,
      },
      {
        key: RouteKeyConstants.LIFE_AND_DISABILITY,
        path: RouteMap.LIFE_AND_DISABILITY,
        label: defaultRouteTitles[RouteKeyConstants.LIFE_AND_DISABILITY],
        icon: <LifeDisabilityIcon />,
      },
      {
        key: RouteKeyConstants.VOLUNTARY_BENEFITS,
        path: RouteMap.VOLUNTARY,
        label: defaultRouteTitles[RouteKeyConstants.VOLUNTARY_BENEFITS],
        icon: <VoluntaryIcon />,
      },
      {
        key: RouteKeyConstants.WELLBEING,
        path: RouteMap.WELLBEING,
        label: defaultRouteTitles[RouteKeyConstants.WELLBEING],
        icon: <WellbeingIcon />,
      },
    ],
  },
  {
    key: RouteKeyConstants.TAX_ADVANTAGED,
    path: 'tax-advantaged/',
    label: defaultRouteTitles[RouteKeyConstants.TAX_ADVANTAGED],
    children: [
      {
        key: RouteKeyConstants.HRA,
        path: RouteMap.HRA,
        label: defaultRouteTitles[RouteKeyConstants.HRA],
        icon: <TaxAdvantagedIcon />,
      },
      {
        key: RouteKeyConstants.FSA,
        path: RouteMap.FSA,
        label: defaultRouteTitles[RouteKeyConstants.FSA],
        icon: <TaxAdvantagedIcon />,
      },
      {
        key: RouteKeyConstants.HSA,
        path: RouteMap.HSA,
        label: defaultRouteTitles[RouteKeyConstants.HSA],
        icon: <TaxAdvantagedIcon />,
      },
      {
        key: RouteKeyConstants.COMMUTER_GENERIC,
        path: RouteMap.COMMUTER,
        label: defaultRouteTitles[RouteKeyConstants.COMMUTER_GENERIC],
        icon: <CommuterIcon />,
      },
    ],
  },
  {
    key: RouteKeyConstants.FOUR_O_ONE_K,
    path: RouteMap.FOUR_O_ONE_K,
    label: defaultRouteTitles[RouteKeyConstants.FOUR_O_ONE_K],
  },
  {
    key: RouteKeyConstants.HOLIDAYS_AND_TIME_OFF,
    path: RouteMap.HOLIDAYS_TIME_OFF,
    label: defaultRouteTitles[RouteKeyConstants.HOLIDAYS_AND_TIME_OFF],
  },
  {
    key: RouteKeyConstants.FAMILY_LEAVE,
    path: RouteMap.FAMILY_AND_LEAVE,
    label: defaultRouteTitles[RouteKeyConstants.FAMILY_LEAVE],
  },
  {
    key: RouteKeyConstants.ADDITIONAL_PERKS,
    path: RouteMap.ADDITIONAL_PERKS,
    label: defaultRouteTitles[RouteKeyConstants.ADDITIONAL_PERKS],
  },
  {
    key: RouteKeyConstants.RESOURCES,
    path: RouteMap.RESOURCES,
    label: defaultRouteTitles[RouteKeyConstants.RESOURCES],
  },
];
