export const RouteKeyConstants = {
  HOME: 'HOME',
  NEW_HIRE_CHECKLIST: 'NEW_HIRE_CHECKLIST',
  HOW_INSURANCE_WORKS: 'HOW_INSURANCE_WORKS',
  DATES_AND_ELIGIBILITY: 'DATES_AND_ELIGIBILITY',
  BENEFITS_WELLNESS: 'BENEFITS_WELLNESS',
  MEDICAL: 'MEDICAL',
  TELEHEALTH: 'TELEHEALTH',
  DENTAL: 'DENTAL',
  VISION: 'VISION',
  LIFE_AND_DISABILITY: 'LIFE_AND_DISABILITY',
  VOLUNTARY_BENEFITS: 'VOLUNTARY_BENEFITS',
  WELLBEING: 'WELLBEING',
  TAX_ADVANTAGED: 'TAX_ADVANTAGED',
  HRA: 'HRA',
  FSA: 'FSA',
  HSA: 'HSA',
  COMMUTER_GENERIC: 'COMMUTER_GENERIC',
  FOUR_O_ONE_K: 'FOUR_O_ONE_K',
  HOLIDAYS_AND_TIME_OFF: 'HOLIDAYS_AND_TIME_OFF',
  FAMILY_LEAVE: 'FAMILY_LEAVE',
  ADDITIONAL_PERKS: 'ADDITIONAL_PERKS',
  RESOURCES: 'RESOURCES',
};

export const defaultRouteTitles = {
  [RouteKeyConstants.HOME]: 'Home',
  [RouteKeyConstants.NEW_HIRE_CHECKLIST]: 'New Hire Checklist',
  [RouteKeyConstants.HOW_INSURANCE_WORKS]: 'How Insurance Works',
  [RouteKeyConstants.DATES_AND_ELIGIBILITY]: 'Dates & Eligibility',
  [RouteKeyConstants.BENEFITS_WELLNESS]: 'Benefits & Wellness',
  [RouteKeyConstants.MEDICAL]: 'Medical',
  [RouteKeyConstants.TELEHEALTH]: 'Telehealth/Rx Delivery',
  [RouteKeyConstants.DENTAL]: 'Dental',
  [RouteKeyConstants.VISION]: 'Vision',
  [RouteKeyConstants.LIFE_AND_DISABILITY]: 'Life & Disability',
  [RouteKeyConstants.VOLUNTARY_BENEFITS]: 'Voluntary Benefits',
  [RouteKeyConstants.WELLBEING]: 'Wellbeing',
  [RouteKeyConstants.TAX_ADVANTAGED]: 'Tax Advantaged Accts',
  [RouteKeyConstants.HRA]: 'HRA',
  [RouteKeyConstants.FSA]: 'FSA',
  [RouteKeyConstants.HSA]: 'HSA',
  [RouteKeyConstants.COMMUTER_GENERIC]: 'Commuter',
  [RouteKeyConstants.FOUR_O_ONE_K]: '401(k)',
  [RouteKeyConstants.HOLIDAYS_AND_TIME_OFF]: 'Holidays & Time Off',
  [RouteKeyConstants.FAMILY_LEAVE]: 'Family & Leave',
  [RouteKeyConstants.ADDITIONAL_PERKS]: 'Additional Perks',
  [RouteKeyConstants.RESOURCES]: 'Resources',
};
