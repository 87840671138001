import React from 'react';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import styles from './customTitlePopover.module.less';
type Props = {
  customTitle: string;
};

const CustomTitlePopover = (props: Props) => {
  const { customTitle } = props;
  return (
    <PlanyearPopover
      placement="right"
      content={
        <div>
          <span className={styles.popoverTitle}>Updated Title: </span>
          <br />
          {customTitle}
        </div>
      }
    >
      <span className={styles.renamedText}>(renamed)</span>
    </PlanyearPopover>
  );
};

export default CustomTitlePopover;
