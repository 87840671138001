import { FC, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useLocation, matchPath } from 'react-router-dom';
import { Button, Layout, Menu, Space, Spin } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { CloseOutlined } from '@ant-design/icons';
import { NavigationContext } from 'context/NavigationContext';
import RouteMap from 'routes/RouteMap';
import { sideNavRoutes } from 'routes/SideNavRoutes';
import useBenguide from 'hooks/benguide';
import { getPathWithHash } from 'utils/routeUtils';
import EmployeeViewSwitch from 'modules/auth/pages/Auth/EmployeViewSwitch';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { trackEvents } from 'utils/initGA4';
import {
  defaultRouteTitles,
  RouteKeyConstants,
} from 'constants/routeKeyConstants';
import Badge, { SizeType } from 'components/Badge/Badge';
import SubmitButton from 'components/buttons/SubmitButton/SubmitButton';
import AiAssistantChat from 'modules/aiAssistantChat/Pages/AiAssistantChat/AiAssistantChat';
import { SideNavItem, SideNavLabel } from 'components/SideNavItem/SideNavItem';
import { URIAuthTypes } from 'modules/auth/constants/authConstants';
import { ReactComponent as ViewIdIcon } from 'images/icons/side-nav/view-id.svg';

import {
  fetchEmployeeLogin,
  setEmployeeModal,
  setToken,
  validateResetPwsToken,
  validateToken,
} from 'modules/auth/slices/employeeAuthSlice';
import ContactSupport from 'components/ContactSupport/ContactSupport';
import EditNavigationTitleModal from 'components/EditNavigationTitleModal/EditNavigationTitleModal';
import { BENGUIDE_MODE_EDIT } from 'modules/auth/constants/globleConstants';
import AiAssistantIcon from 'modules/aiAssistantChat/components/AiAssistantIcon/AiAssistantIcon';
import HeaderLogo from './components/HeaderLogo/HeaderLogo';

import styles from './layout.module.less';

const { Sider } = Layout;

type AppSiderProps = {
  collapsed: boolean;
  onCloseSider: () => void;
};

const AppSider: FC<AppSiderProps> = ({
  collapsed,
  onCloseSider,
}: AppSiderProps) => {
  const [openSubMenus, setOpenSubMenus] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { isEditMode } = useBenguide();
  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData, inProgress, customNavigationTitles } = benguide;
  const {
    masterId = '',
    employerLogo,
    defaultEmployerLogo,
    originalEmployerLogo,
    employerLogoCropParams,
    defaultOriginalEmployerLogo,
    defaultEmployerLogoCropParams,
    isIdCardEnable,
    isAiAssistantChatEnabled,
    publishedBenefitGuide,
  } = benguideData || {};

  const { isTablet, isMobile } = useAppSelector((state) => state.layout);

  const { hash = '', employerName = '' } = useParams();
  const baseUrl = `/${hash}/`;

  const { disabledRoutes } = useContext(NavigationContext);

  const location = useLocation();
  const basicInfoEditPath = `${baseUrl}${RouteMap.BASIC_INFO_EDIT_PATH}`;
  const basicInfoEditPathWithEmpName = `${baseUrl}${employerName}/${RouteMap.BASIC_INFO_EDIT_PATH}`;

  const mode = useAppSelector((state) => state.benguide.mode);
  const aiAssistantChatState = useAppSelector(
    (state) => state.persisted.aiAssistantChat
  );
  const { messages } = aiAssistantChatState;

  const search = useLocation().search;
  const previewToken = new URLSearchParams(search).get('previewToken');

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [editNavigationTitleModal, setEditNavigationTitleModal] = useState<{
    visible: boolean;
    key: keyof typeof RouteKeyConstants | null;
  }>({
    visible: false,
    key: null,
  });
  const openEmployeeLoginModal = () => {
    dispatch(setEmployeeModal(true));
  };

  const handleOpenAiAssistantChat = () => {
    setIsChatOpen(true);
    trackEvents({
      category: 'AI Assistant',
      action: 'open_ai_assistant',
      label: 'Open AI Assistant',
    });
  };

  useEffect(() => {
    const selectedSubRoute = sideNavRoutes.find((route) => {
      const selectedChild = route.children?.find(
        (subRoute) =>
          subRoute &&
          getPathWithHash(hash, subRoute.path, employerName) ===
            location.pathname
      );
      return Boolean(selectedChild);
    });
    if (selectedSubRoute) {
      setOpenSubMenus([selectedSubRoute.path]);
    }
  }, [location.pathname, hash, employerName]);

  useEffect(() => {
    if (isMobile || isTablet) {
      setOpenSubMenus([]);
    }
  }, [isMobile, isTablet]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const getTokeFromUrlParams = () => {
    const currentURL = window.location.search;
    const urlSearchParams = new URLSearchParams(currentURL);

    if (urlSearchParams.has('token') && urlSearchParams.has('a')) {
      const token = urlSearchParams.get('token');
      const type = urlSearchParams.get('a');
      dispatch(setToken(token));
      return { token, type };
    }

    const showId = urlSearchParams.get(URIAuthTypes.ID_CARD);

    if (showId === 'true') {
      dispatch(fetchEmployeeLogin(benguideData?.planYearId));
    } else {
      dispatch(setToken(null));
      return null;
    }
  };

  const initializeEmployeeModal = async () => {
    const URI = getTokeFromUrlParams();
    if (!URI || !URI.token || !URI.type) return;

    if (URI.type === URIAuthTypes.DBG_SIGNUP) {
      dispatch(validateToken(URI.token));
    } else if (URI.type === URIAuthTypes.DBG_RESET_PWS) {
      dispatch(validateResetPwsToken(URI.token));
    }
  };

  useEffect(() => {
    initializeEmployeeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenChange = (event: any) => {
    setOpenSubMenus(event);
  };

  if (
    matchPath(location.pathname, basicInfoEditPath) ||
    matchPath(location.pathname, basicInfoEditPathWithEmpName) ||
    matchPath(location.pathname, RouteMap.CREATE_BENGUIDE)
  ) {
    return <></>;
  }

  return (
    <>
      {' '}
      <div
        className={`${styles.sideNav} ${
          mode !== BENGUIDE_MODE_EDIT ? styles.prevSideNav : ''
        }`}
      >
        <Sider
          className={`${styles.sideNav} ${
            mode !== BENGUIDE_MODE_EDIT ? styles.prevSideNav : ''
          } ${isTablet ? styles.siderBroken : ''} ${
            isMobile && collapsed ? styles.mobileHidden : ''
          } ${isMobile ? styles.mobile : ''}`}
          breakpoint="lg"
          collapsed={collapsed && isTablet}
          collapsedWidth={isMobile ? '100%' : '0%'}
          zeroWidthTriggerStyle={{ display: 'none' }}
          width={isMobile ? '100%' : isTablet ? '50%' : '220px'}
        >
          {benguideData && !isTablet && (
            <HeaderLogo
              employerLogo={employerLogo}
              masterId={masterId}
              defaultEmployerLogo={defaultEmployerLogo}
              logoCropParams={employerLogoCropParams}
              originalLogoRef={originalEmployerLogo}
              defaultOriginalEmployerLogo={defaultOriginalEmployerLogo}
              defaultEmployerLogoCropParams={defaultEmployerLogoCropParams}
              className={`${styles.logoContainer} ${
                mode !== BENGUIDE_MODE_EDIT ? styles.view : ''
              }`}
            />
          )}
          {inProgress && (
            <>
              <Space size="middle">
                <Spin size="large" />
              </Space>
            </>
          )}
          <Menu
            theme="light"
            mode="inline"
            className={styles.navMenu}
            key="side-nav-menu"
            selectedKeys={[location.pathname]}
            openKeys={openSubMenus}
            onOpenChange={handleOpenChange}
            onSelect={() => {
              isMobile && onCloseSider();
              isTablet && onCloseSider();
            }}
          >
            {isTablet && !isMobile && (
              <Menu.Item
                title={null}
                className={styles.closeBtnContainer}
                key="close-drawer-itm"
              >
                <Button
                  onClick={onCloseSider}
                  type="text"
                  className={styles.drawerCloseBtn}
                >
                  <CloseOutlined />
                </Button>
              </Menu.Item>
            )}
            {sideNavRoutes.map((route) => {
              const enabledChildRoutes = route.children
                ? route.children.filter(
                    (childRoute) => !disabledRoutes.includes(childRoute.path)
                  )
                : [];

              if (enabledChildRoutes.length > 0) {
                const customNavTitle = customNavigationTitles?.data?.[
                  route.key as keyof typeof RouteKeyConstants
                ]!
                  ? customNavigationTitles?.data[
                      route.key as keyof typeof RouteKeyConstants
                    ]!
                  : defaultRouteTitles[route.key];
                return (
                  <SubMenu
                    key={route.path}
                    title={
                      <SideNavLabel
                        label={customNavTitle || ''}
                        isEditMode={isEditMode}
                        onEditIconClick={() => {
                          setEditNavigationTitleModal({
                            visible: true,
                            key: route.key as keyof typeof RouteKeyConstants,
                          });
                        }}
                      />
                    }
                  >
                    {enabledChildRoutes.map(({ key, path, icon }) => {
                      const customNavTitle = customNavigationTitles?.data?.[
                        key as keyof typeof RouteKeyConstants
                      ]!
                        ? customNavigationTitles?.data[
                            key as keyof typeof RouteKeyConstants
                          ]!
                        : defaultRouteTitles[key];
                      return (
                        <SideNavItem
                          key={getPathWithHash(hash, path, employerName)}
                          path={path}
                          label={customNavTitle || ''}
                          icon={icon}
                          previewToken={previewToken}
                          hash={hash}
                          employerName={employerName}
                          isEditMode={isEditMode}
                          onEditIconClick={() => {
                            setEditNavigationTitleModal({
                              visible: true,
                              key: key as keyof typeof RouteKeyConstants,
                            });
                          }}
                        />
                      );
                    })}
                  </SubMenu>
                );
              }
              if (
                Object.values(RouteMap).includes(route.path as RouteMap) &&
                !disabledRoutes.includes(route.path as RouteMap)
              ) {
                const customNavTitle = customNavigationTitles?.data?.[
                  route.key as keyof typeof RouteKeyConstants
                ]!
                  ? customNavigationTitles?.data[
                      route.key as keyof typeof RouteKeyConstants
                    ]!
                  : defaultRouteTitles[route.key];
                return (
                  <SideNavItem
                    key={getPathWithHash(hash, route.path, employerName)}
                    path={route.path}
                    label={customNavTitle || ''}
                    previewToken={previewToken}
                    hash={hash}
                    employerName={employerName}
                    isEditMode={isEditMode}
                    onEditIconClick={() => {
                      setEditNavigationTitleModal({
                        visible: true,
                        key: route.key as keyof typeof RouteKeyConstants,
                      });
                    }}
                  />
                );
              }
              return null;
            })}
          </Menu>
          {(!collapsed || !isTablet) && (
            <div className={styles.contactSupport}>
              {isAiAssistantChatEnabled && !!publishedBenefitGuide && (
                <SubmitButton
                  type="link"
                  wrapperClassName={styles.aiAssistantBtnWrapper}
                  className={styles.aiAssistantBtn}
                  onClick={handleOpenAiAssistantChat}
                >
                  <span className={styles.aiAssistantIcon}>
                    <AiAssistantIcon />
                  </span>
                  AI Assistant
                  {!isChatOpen && messages.length !== 0 && (
                    <Badge className={styles.badge} size={SizeType.SMALL} />
                  )}
                </SubmitButton>
              )}
              {isIdCardEnable && !!publishedBenefitGuide && (
                <SubmitButton
                  onClick={() => openEmployeeLoginModal()}
                  className={styles.viewIdButton}
                  wrapperClassName={styles.viewIdBtnWrapper}
                >
                  <ViewIdIcon
                    className={`${styles.viewIdIcon} ${
                      isEditMode && styles.viewIdIconEdit
                    }`}
                  />
                  <span
                    className={`${styles.viewIdBtn} ${
                      isEditMode && styles.viewIdBtnEdit
                    }`}
                  >
                    View ID Cards
                  </span>
                </SubmitButton>
              )}
              <ContactSupport />
            </div>
          )}
        </Sider>
      </div>
      <EmployeeViewSwitch />
      <AiAssistantChat isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen} />
      <EditNavigationTitleModal
        visible={editNavigationTitleModal.visible}
        titleKey={editNavigationTitleModal.key}
        onClose={() => {
          setEditNavigationTitleModal({ visible: false, key: null });
        }}
      />
    </>
  );
};

export default AppSider;
