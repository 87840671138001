import RouteMap from 'routes/RouteMap';
import { RouteKeyConstants } from 'constants/routeKeyConstants';

export type ToolbarItem = {
  path: RouteMap | ToolbarSubmenu;
  label: string;
  children?: ToolbarItem[];
  planPath?: string;
  customTitleKey?: keyof typeof RouteKeyConstants;
};

export enum ToolbarSubmenu {
  TAX_ADVANTAGED = 'tax-advantaged',
}

export const toolbarRoutes: ToolbarItem[] = [
  {
    path: RouteMap.BASIC_INFO_EDIT_PATH,
    label: 'Basic Guide Info',
  },
  {
    path: RouteMap.HOME_PATH,
    label: 'Home Screen',
    customTitleKey: RouteKeyConstants.HOME as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.NEW_HIRE_CHECKLIST,
    label: 'New Hire Checklist',
    customTitleKey:
      RouteKeyConstants.NEW_HIRE_CHECKLIST as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.HOW_INSURANCE_WORKS,
    label: 'How Insurance Works',
    customTitleKey:
      RouteKeyConstants.HOW_INSURANCE_WORKS as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.DATES_AND_ELIGIBILITY,
    label: 'Dates & Eligibility',
    customTitleKey:
      RouteKeyConstants.DATES_AND_ELIGIBILITY as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.MEDICAL,
    label: 'Medical',
    planPath: 'medical.medicalSection.plans',
    customTitleKey: RouteKeyConstants.MEDICAL as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.TELEHEALTH,
    label: 'Telehealth/Rx Delivery',
    planPath: 'telehealth.telehealthSection.plans',
    customTitleKey:
      RouteKeyConstants.TELEHEALTH as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.DENTAL,
    label: 'Dental',
    planPath: 'dental.dentalSection.plans',
    customTitleKey: RouteKeyConstants.DENTAL as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.VISION,
    label: 'Vision',
    planPath: 'vision.visionSection.plans',
    customTitleKey: RouteKeyConstants.VISION as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.LIFE_AND_DISABILITY,
    label: 'Life & Disability',
    planPath: 'lifeAndDisability.lifeAndDisabilitySection.plans',
    customTitleKey:
      RouteKeyConstants.LIFE_AND_DISABILITY as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.VOLUNTARY,
    label: 'Voluntary Benefits',
    planPath: 'voluntaryBenefits.volBenefitsSection.plans',
    customTitleKey:
      RouteKeyConstants.VOLUNTARY_BENEFITS as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.WELLBEING,
    label: 'Wellbeing',
    planPath: 'wellbeing.wellbeingSection.plans',
    customTitleKey:
      RouteKeyConstants.WELLBEING as keyof typeof RouteKeyConstants,
  },
  {
    path: ToolbarSubmenu.TAX_ADVANTAGED,
    label: 'Tax Advantaged Accounts',
    customTitleKey:
      RouteKeyConstants.TAX_ADVANTAGED as keyof typeof RouteKeyConstants,
    children: [
      {
        path: RouteMap.HRA,
        label: 'HRA',
        planPath: 'hra.hraSection.plans',
        customTitleKey: RouteKeyConstants.HRA as keyof typeof RouteKeyConstants,
      },
      {
        path: RouteMap.FSA,
        label: 'FSA',
        planPath: 'fsa.fsaSection.plans',
        customTitleKey: RouteKeyConstants.FSA as keyof typeof RouteKeyConstants,
      },
      {
        path: RouteMap.HSA,
        label: 'HSA',
        planPath: 'hsa.hsaSection.plans',
        customTitleKey: RouteKeyConstants.HSA as keyof typeof RouteKeyConstants,
      },
      {
        path: RouteMap.COMMUTER,
        label: 'Commuter',
        planPath: 'commuter.commuterSection.plans',
        customTitleKey:
          RouteKeyConstants.COMMUTER_GENERIC as keyof typeof RouteKeyConstants,
      },
    ],
  },
  {
    path: RouteMap.FOUR_O_ONE_K,
    label: '401(k)',
    planPath: 'fourOOneK.fourOOneKSection.plans',
    customTitleKey:
      RouteKeyConstants.FOUR_O_ONE_K as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.HOLIDAYS_TIME_OFF,
    label: 'Holidays & Time Off',
    planPath: 'holidayAndTimeOff.holidayAndTimeOffSection.plans',
    customTitleKey:
      RouteKeyConstants.HOLIDAYS_AND_TIME_OFF as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.FAMILY_AND_LEAVE,
    label: 'Family & Leave',
    planPath: 'familyAndLeave.familyAndLeaveSection.plans',
    customTitleKey:
      RouteKeyConstants.FAMILY_LEAVE as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.ADDITIONAL_PERKS,
    label: 'Additional Perks',
    planPath: 'additionalPerks.additionalPerksSection.plans',
    customTitleKey:
      RouteKeyConstants.ADDITIONAL_PERKS as keyof typeof RouteKeyConstants,
  },
  {
    path: RouteMap.RESOURCES,
    label: 'Resources',
    customTitleKey:
      RouteKeyConstants.RESOURCES as keyof typeof RouteKeyConstants,
  },
];
