import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { AxiosError } from 'axios';
import * as ResourceService from 'modules/resources/services/ResourceService';
import * as BenGuideService from 'modules/home/services/BenGuideService';
import { getRichMedia } from 'modules/auth/services/BenguideService';
import * as MedicalPlanService from 'modules/benefitsWellness/services/MedicalPlanService';
import { getLatestBenefitGuideByHash } from 'services/BenGuideService';
import BenefitGuide from 'model/BenefitGuide';
import Plan from 'model/Plan';
import { SectionName } from 'modules/home/constants';
import {
  CARRIER_TYPE,
  TAX_ADVANTAGED_ACCOUNT,
} from 'modules/resources/constants';
import FileType from 'model/FileType';
import WebLinkType from 'model/WebLinkType';
import * as AuthService from 'modules/auth/services/AuthService';
import EditDocType from 'model/EditDocType';
import { RouteKeyConstants } from 'constants/routeKeyConstants';

type ResourcesState = {
  inProgress: boolean;
  error: any;
  resources: any[];
  richMedia: {};
  resUploadInProgress: boolean;
  resUploadSuccess: boolean;
  latestBenefitguide: BenefitGuide;
  currentContent: string;
  defaultContent: string;
  planDocsUploadInProgress: boolean;
  planDocsUploadSuccess: boolean;
  deleteInProgress: boolean;
  isResRemovedSuccess: boolean;
  planDocsRemoveInProgress: boolean;
  planDocsRemoveSuccess: boolean;
  removePlanDocumentInProgress: boolean;
  removePlanDocumentSuccess: boolean;
  removePlanRes: Plan;
  planDocuments: {};
  requestType: string;
  documentList: any[];
  allPlanDocsUpdatingSuccess: boolean;
};

const initialState = {
  resources: [],
  richMedia: {},
  inProgress: false,
  error: null,
  resUploadInProgress: false,
  resUploadSuccess: false,
  latestBenefitguide: {} as BenefitGuide,
  currentContent: '',
  defaultContent: '',
  planDocsUploadInProgress: false,
  planDocsUploadSuccess: false,
  deleteInProgress: false,
  isResRemovedSuccess: false,
  planDocsRemoveInProgress: false,
  planDocsRemoveSuccess: false,
  removePlanDocumentInProgress: false,
  removePlanDocumentSuccess: false,
  removePlanRes: {} as Plan,
  planDocuments: {},
  requestType: '',
  documentList: [],
  allPlanDocsUpdatingSuccess: false,
} as ResourcesState;

const currentContentInProgress = (state: ResourcesState) => {
  state.inProgress = true;
  state.currentContent = '';
  state.error = null;
};

const currentContentCompleted = (
  state: ResourcesState,
  action: PayloadAction<string>
) => {
  state.inProgress = false;
  state.currentContent = action.payload;
  state.error = null;
};

const currentContentFailed = (state: ResourcesState, action: PayloadAction) => {
  state.inProgress = false;
  state.currentContent = '';
  state.error = action.payload;
};

const documentUploadStarted = (
  state: ResourcesState,
  action: PayloadAction
) => {
  state.inProgress = true;
  state.planDocuments = {};
  state.error = null;
  state.requestType = action.type;
};

const documentUploadCompleted = (
  state: ResourcesState,
  action: PayloadAction<object>
) => {
  state.inProgress = false;
  state.planDocuments = action.payload;
  state.error = null;
  state.requestType = action.type;
  state.documentList.push(action.payload);
};

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    getDefaultRichMediaStarted(state) {
      state.inProgress = true;
      state.defaultContent = '';
      state.error = null;
    },
    getDefaultRichMediaSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.defaultContent = action.payload;
      state.error = null;
    },
    getDefaultRichMediaFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.defaultContent = '';
      state.error = action.payload;
    },

    createRichMediaStarted(state) {
      currentContentInProgress(state);
    },
    createRichMediaSuccess(state, action: PayloadAction<string>) {
      currentContentCompleted(state, action);
    },
    createRichMediaFailed(state, action: PayloadAction<any>) {
      currentContentFailed(state, action);
    },

    getCurrentRichMediaStarted(state) {
      currentContentInProgress(state);
    },
    getCurrentRichMediaSuccess(state, action: PayloadAction<string>) {
      currentContentCompleted(state, action);
    },
    getCurrentRichMediaFailed(state, action: PayloadAction<any>) {
      currentContentFailed(state, action);
    },

    initResourcesCompleted: (state, { payload }) => {
      state.inProgress = false;
      state.resources = payload;
      state.error = null;
    },
    additionalResUploadingStarted: (state) => {
      state.resUploadInProgress = true;
    },
    additionalResUploadingCompleted: (state) => {
      state.resUploadInProgress = false;
      state.resUploadSuccess = true;
    },
    additionalResUploadingFailed: (state, action: PayloadAction<any>) => {
      state.resUploadInProgress = false;
      state.resUploadSuccess = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
    },
    clearAdditionalResUpload: (state) => {
      state.resUploadInProgress = false;
      state.resUploadSuccess = false;
      state.error = null;
    },
    setLatestBenefitGuideCompleted: (state, { payload }) => {
      state.latestBenefitguide = payload;
    },
    setLatestBenefitGuideFailed: (state, { payload }) => {
      state.latestBenefitguide = {} as BenefitGuide;
      state.error = payload;
    },
    removeResourceStarted(state) {
      state.deleteInProgress = true;
    },
    removeResourceCompleted(state) {
      state.deleteInProgress = false;
      state.error = null;
      state.isResRemovedSuccess = true;
    },
    removeResourceFailed(state, action: PayloadAction<any>) {
      state.deleteInProgress = false;
      state.error = action.payload;
    },
    clearRemovedResource(state) {
      state.isResRemovedSuccess = true;
    },
    removePlanDocumentStarted(state) {
      state.planDocsRemoveInProgress = true;
    },
    removePlanDocumentCompleted(state) {
      state.error = null;
      state.planDocsRemoveSuccess = true;
    },
    removePlanDocumentFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = action.payload;
    },
    planDocsRemovingStarted: (state) => {
      state.removePlanDocumentInProgress = true;
    },
    planDocsRemovingCompleted: (state, { payload }) => {
      state.removePlanDocumentInProgress = false;
      state.removePlanDocumentSuccess = true;
      state.removePlanRes.id = payload.id;
      state.removePlanRes.benefitKind = payload.benefitKind;
      state.removePlanRes.documentReferences = payload.documentReferences;
    },
    clearPlanDocsRemoval: (state) => {
      state.planDocsRemoveSuccess = false;
    },
    updatePlanDocumentsStarted: (state, action: PayloadAction) => {
      documentUploadStarted(state, action);
    },
    updatePlanDocumentsCompleted: (state, action: PayloadAction<object>) => {
      documentUploadCompleted(state, action);
    },
    uploadPlanDocumentsStarted: (state, action: PayloadAction) => {
      documentUploadStarted(state, action);
    },
    uploadPlanDocumentsCompleted: (state, action: PayloadAction<object>) => {
      documentUploadCompleted(state, action);
    },
    uploadPlanDocumentsFailed: (state, action) => {
      state.inProgress = false;
      state.planDocuments = {};
      state.error = action.payload;
      state.requestType = action.type;
    },
    allPlanDocsUpdated: (state) => {
      state.allPlanDocsUpdatingSuccess = true;
      state.inProgress = false;
    },
  },
});

export const {
  getDefaultRichMediaStarted,
  getDefaultRichMediaSuccess,
  getDefaultRichMediaFailed,
  createRichMediaStarted,
  createRichMediaSuccess,
  createRichMediaFailed,
  getCurrentRichMediaStarted,
  getCurrentRichMediaSuccess,
  getCurrentRichMediaFailed,
  initResourcesCompleted,
  additionalResUploadingStarted,
  additionalResUploadingCompleted,
  additionalResUploadingFailed,
  clearAdditionalResUpload,
  setLatestBenefitGuideCompleted,
  setLatestBenefitGuideFailed,
  removeResourceStarted,
  removeResourceCompleted,
  removeResourceFailed,
  clearRemovedResource,
  removePlanDocumentStarted,
  removePlanDocumentCompleted,
  removePlanDocumentFailed,
  planDocsRemovingStarted,
  planDocsRemovingCompleted,
  clearPlanDocsRemoval,
  updatePlanDocumentsStarted,
  updatePlanDocumentsCompleted,
  uploadPlanDocumentsStarted,
  uploadPlanDocumentsCompleted,
  allPlanDocsUpdated,
  uploadPlanDocumentsFailed,
} = resourcesSlice.actions;

export const initResourceData = () => {
  return (dispatch: Dispatch, getState: Function) => {
    const { latestBenefitguide } = getState().resources;
    const {
      medical,
      dental,
      vision,
      wellbeing,
      fourOOneK,
      lifeAndDisability,
      volBenefits,
      telehealth,
      hsa,
      fsa,
      hra,
      commuter,
    } = latestBenefitguide;

    const resources = [] as any;
    const { plans: medicalPlans } = getState().medical.medicalSection;
    const { plans: dentalPlans } = getState().dental.dentalSection;
    const { plans: visionPlans } = getState().vision.visionSection;
    const { plans: fourOOneKPlans } = getState().fourOOneK.fourOOneKSection;
    const { plans: wellbeingPlans } = getState().wellbeing.wellbeingSection;
    const { plans: volBenefitsPlans } =
      getState().voluntaryBenefits.volBenefitsSection;
    const { plans: telehealthPlans } = getState().telehealth.telehealthSection;
    const { data: lifePlans } = getState().lifeAndDisability.selectedPlans;
    const { data: hsaPlans } = getState().hsa.selectedPlans;
    const { data: hraPlans } = getState().hra.selectedPlans;
    const { data: fsaPlans } = getState().fsa.selectedPlans;
    const { data: commuterPlans } = getState().commuter.selectedPlans;

    const getProviderPlans = (plan: any, newResource: any) => {
      return (
        plan.benefitCarrier &&
        plan.benefitCarrier.name === newResource.carrierName &&
        plan.benefitCarrier.benefitCategory === newResource.type.toUpperCase()
      );
    };

    const filterProviderPlans = (plan: any, newResource: any) => {
      return (
        plan.benefitCarrier &&
        plan.benefitCarrier.name === newResource.carrierName
      );
    };

    if (medical && medical.enabled && medical.plans.length > 0) {
      medical.plans.forEach((plan: any) => {
        const { benefitCarrier = {} } = plan;
        if (benefitCarrier && benefitCarrier.benefitCategory) {
          if (
            !resources.some(
              (resource: any) =>
                resource.type.toUpperCase() ===
                  benefitCarrier.benefitCategory &&
                resource.carrierName === benefitCarrier.name
            )
          ) {
            const newResource = {
              type: 'Medical',
              carrierName: benefitCarrier.name,
              carrierUrl: benefitCarrier.url,
              phoneNumber: benefitCarrier.phoneNumber,
              benefitKind: 'MEDICAL',
              customNavKey: RouteKeyConstants.MEDICAL,
              plans: [],
            };

            const providerPlans = medicalPlans.filter((plan: any) => {
              return getProviderPlans(plan, newResource);
            });
            newResource.plans = providerPlans;

            resources.push(newResource);
          }
        }
      });
    }

    if (telehealth && telehealth.enabled && telehealth.plans.length > 0) {
      telehealthPlans.forEach((plan: any) => {
        let isPlanAlreadyAdded: Boolean = false;
        resources.forEach((resource: any) => {
          if (
            resource.type === 'Telehealth/Rx Delivery' &&
            plan.benefitCarrier.name === resource.carrierName
          ) {
            isPlanAlreadyAdded = true;
          }
        });
        const { benefitCarrier = {} } = plan;

        if (
          benefitCarrier &&
          benefitCarrier.benefitCategory &&
          !isPlanAlreadyAdded
        ) {
          if (
            !resources.some(
              (resource: any) =>
                (CARRIER_TYPE.TELEHEALTH === benefitCarrier.benefitCategory ||
                  CARRIER_TYPE.RX_DELIVERY_RX_COUPONS ===
                    benefitCarrier.benefitCategory) &&
                resource.carrierName === benefitCarrier.name
            )
          ) {
            const newResource = {
              type: 'Telehealth/Rx Delivery',
              carrierName: benefitCarrier.name,
              carrierUrl: benefitCarrier.url,
              phoneNumber: benefitCarrier.phoneNumber,
              benefitKind: plan.benefitKind,
              customNavKey: RouteKeyConstants.TELEHEALTH,
              plans: [],
            };

            const providerPlans = telehealthPlans.filter((plan: any) => {
              return filterProviderPlans(plan, newResource);
            });

            newResource.plans = providerPlans;

            resources.push(newResource);
          }
        }
      });
    }

    if (dental && dental.enabled && dental.plans.length > 0) {
      dental.plans.forEach((plan: any) => {
        const { benefitCarrier = {} } = plan;
        if (benefitCarrier && benefitCarrier.benefitCategory) {
          if (
            !resources.some(
              (resource: any) =>
                resource.type.toUpperCase() ===
                  benefitCarrier.benefitCategory &&
                resource.carrierName === benefitCarrier.name
            )
          ) {
            const newResource = {
              type: 'Dental',
              carrierName: benefitCarrier.name,
              carrierUrl: benefitCarrier.url,
              phoneNumber: benefitCarrier.phoneNumber,
              benefitKind: 'DENTAL',
              customNavKey: RouteKeyConstants.DENTAL,
              plans: [],
            };

            const providerPlans = dentalPlans.filter((plan: any) => {
              return getProviderPlans(plan, newResource);
            });

            newResource.plans = providerPlans;

            resources.push(newResource);
          }
        }
      });
    }

    if (vision && vision.enabled && vision.plans.length > 0) {
      vision.plans.forEach((plan: any) => {
        const { benefitCarrier = {} } = plan;

        if (benefitCarrier && benefitCarrier.benefitCategory) {
          if (
            !resources.some(
              (resource: any) =>
                resource.type.toUpperCase() ===
                  benefitCarrier.benefitCategory &&
                resource.carrierName === benefitCarrier.name
            )
          ) {
            const newResource = {
              type: 'Vision',
              carrierName: benefitCarrier.name,
              carrierUrl: benefitCarrier.url,
              phoneNumber: benefitCarrier.phoneNumber,
              benefitKind: 'VISION',
              customNavKey: RouteKeyConstants.VISION,
              plans: [],
            };

            const providerPlans = visionPlans.filter((plan: any) => {
              return getProviderPlans(plan, newResource);
            });

            newResource.plans = providerPlans;

            resources.push(newResource);
          }
        }
      });
    }

    if (
      lifeAndDisability &&
      lifeAndDisability.enabled &&
      lifeAndDisability.plans.length > 0
    ) {
      lifeAndDisability.plans.forEach((plan: any) => {
        let isPlanAlreadyAdded: Boolean = false;
        resources.forEach((resource: any) => {
          if (
            resource.type === 'Life & Disability' &&
            plan.benefitCarrier.name === resource.carrierName
          ) {
            isPlanAlreadyAdded = true;
          }
        });
        const { benefitCarrier = {} } = plan;

        if (
          benefitCarrier &&
          benefitCarrier.benefitCategory &&
          !isPlanAlreadyAdded
        ) {
          if (
            !resources.some(
              (resource: any) =>
                'LIFE' === benefitCarrier.benefitCategory &&
                resource.carrierName === benefitCarrier.name &&
                resource.plans.some(
                  (planObj: any) => planObj.id === plan.planId
                )
            )
          ) {
            const newResource = {
              type: 'Life & Disability',
              carrierName: benefitCarrier.name,
              carrierUrl: benefitCarrier.url,
              phoneNumber: benefitCarrier.phoneNumber,
              benefitKind: plan.benefitKind,
              customNavKey: RouteKeyConstants.LIFE_AND_DISABILITY,
              plans: [],
            };

            const providerPlans = lifePlans.filter((plan: any) => {
              return filterProviderPlans(plan, newResource);
            });

            newResource.plans = providerPlans;

            resources.push(newResource);
          }
        }
      });
    }

    if (volBenefits && volBenefits.enabled && volBenefits.plans.length > 0) {
      volBenefits.plans.forEach((plan: any) => {
        let isPlanAlreadyAdded: Boolean = false;
        resources.forEach((resource: any) => {
          if (
            resource.type === 'VOLUNTARY_BENEFIT' &&
            plan.benefitCarrier.name === resource.carrierName
          ) {
            isPlanAlreadyAdded = true;
          }
        });
        const { benefitCarrier = {} } = plan;

        if (
          benefitCarrier &&
          benefitCarrier.benefitCategory &&
          !isPlanAlreadyAdded
        ) {
          if (
            !resources.some(
              (resource: any) =>
                'VOLUNTARY_BENEFIT' === benefitCarrier.benefitCategory &&
                resource.carrierName === benefitCarrier.name &&
                resource.plans.some(
                  (planObj: any) => planObj.id === plan.planId
                )
            )
          ) {
            const newResource = {
              type: 'Voluntary Benefits',
              carrierName: benefitCarrier.name,
              carrierUrl: benefitCarrier.url,
              phoneNumber: benefitCarrier.phoneNumber,
              benefitKind: plan.benefitKind,
              customNavKey: RouteKeyConstants.VOLUNTARY_BENEFITS,
              plans: [],
            };

            const providerPlans = volBenefitsPlans.filter((plan: any) => {
              return filterProviderPlans(plan, newResource);
            });
            newResource.plans = providerPlans;

            resources.push(newResource);
          }
        }
      });
    }

    if (wellbeing && wellbeing.enabled && wellbeing.plans.length > 0) {
      wellbeing.plans.forEach(
        (plan: { benefitCarrier: any; benefitKinds?: any }) => {
          const { benefitCarrier = {} } = plan;

          if (benefitCarrier && benefitCarrier.benefitCategory) {
            if (
              !resources.some(
                (resource: { type: string; carrierName: any }) =>
                  resource.type.toUpperCase() ===
                    benefitCarrier.benefitCategory &&
                  resource.carrierName === benefitCarrier.name
              )
            ) {
              const newResource = {
                type: 'WELLBEING',
                carrierName: benefitCarrier.name,
                carrierUrl: benefitCarrier.url,
                phoneNumber: benefitCarrier.phoneNumber,
                benefitKind: 'WELLBEING',
                customNavKey: RouteKeyConstants.WELLBEING,
                plans: [],
              };

              const providerPlans = wellbeingPlans.filter(
                (plan: { benefitCarrier: { name: string }; documents: {} }) => {
                  return filterProviderPlans(plan, newResource);
                }
              );
              newResource.plans = providerPlans;

              resources.push(newResource);
            }
          }
        }
      );
    }

    if (fourOOneK && fourOOneK.enabled && fourOOneK.plans.length > 0) {
      fourOOneK.plans.forEach(
        (plan: { benefitCarrier: any; benefitKinds?: any }) => {
          const { benefitCarrier = {} } = plan;

          if (benefitCarrier && benefitCarrier.benefitCategory) {
            if (
              !resources.some(
                (resource: { type: string; carrierName: any }) =>
                  resource.type.toUpperCase() ===
                    benefitCarrier.benefitCategory &&
                  resource.carrierName === benefitCarrier.name
              )
            ) {
              const newResource = {
                type: 'RETIREMENT',
                carrierName: benefitCarrier.name,
                carrierUrl: benefitCarrier.url,
                phoneNumber: benefitCarrier.phoneNumber,
                benefitKind: plan.benefitKinds
                  ? plan.benefitKinds
                  : 'RETIREMENT_401K',
                customNavKey: RouteKeyConstants.FOUR_O_ONE_K,
                plans: [],
              };

              const providerPlans = fourOOneKPlans.filter(
                (plan: { benefitCarrier: { name: string } }) => {
                  return filterProviderPlans(plan, newResource);
                }
              );
              newResource.plans = providerPlans;

              resources.push(newResource);
            }
          }
        }
      );
    }

    if (
      (hsa && hsa.enabled && hsa.plans.length > 0) ||
      (hra && hra.enabled && hra.plans.length > 0) ||
      (fsa && fsa.enabled && fsa.plans.length > 0) ||
      (commuter && commuter.enabled && commuter.plans.length > 0)
    ) {
      const taxAdvantagedPlans = hraPlans
        .concat(fsaPlans)
        .concat(hsaPlans)
        .concat(commuterPlans);
      taxAdvantagedPlans.forEach((plan: any) => {
        let isPlanAlreadyAdded: Boolean = false;
        resources.forEach((resource: any) => {
          if (
            resource.type === 'Tax Advantaged Accounts' &&
            plan.benefitCarrier.name === resource.carrierName
          ) {
            isPlanAlreadyAdded = true;
          }
        });
        const { benefitCarrier = {} } = plan;

        if (
          benefitCarrier &&
          benefitCarrier.benefitCategory &&
          !isPlanAlreadyAdded
        ) {
          if (
            !resources.some(
              (resource: any) =>
                (CARRIER_TYPE.COMMUTER === benefitCarrier.benefitCategory ||
                  CARRIER_TYPE.SAVING === benefitCarrier.benefitCategory) &&
                resource.carrierName === benefitCarrier.name &&
                resource.plans.some(
                  (planObj: any) => planObj.id === plan.planId
                )
            )
          ) {
            const newResource = {
              type: 'Tax Advantaged Accounts',
              carrierName: benefitCarrier.name,
              carrierUrl: benefitCarrier.url,
              phoneNumber: benefitCarrier.phoneNumber,
              benefitKind: TAX_ADVANTAGED_ACCOUNT,
              customNavKey: RouteKeyConstants.TAX_ADVANTAGED,
              plans: [],
            };

            const providerPlans = taxAdvantagedPlans.filter((plan: any) => {
              return filterProviderPlans(plan, newResource);
            });

            newResource.plans = providerPlans;

            resources.push(newResource);
          }
        }
      });
    }

    dispatch(initResourcesCompleted(resources));
  };
};

export const getDefaultRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getDefaultRichMediaStarted());
    try {
      const response = await BenGuideService.getDefaultRichMedia(
        benguideId,
        sectionName
      );
      dispatch(getDefaultRichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getDefaultRichMediaFailed(error));
    }
  };

export const createRichMedia =
  (benguideId: string, sectionName: SectionName, content: string) =>
  async (dispatch: Dispatch) => {
    dispatch(createRichMediaStarted());
    try {
      const data = {
        sectionName: sectionName,
        content: content,
      };
      await BenGuideService.createRichMedia(benguideId, data);
      dispatch(createRichMediaSuccess(data.content));
    } catch (error) {
      dispatch(createRichMediaFailed(error));
    }
  };

export const getCurrentRichMedia =
  (benguideId: string, sectionName: SectionName) =>
  async (dispatch: Dispatch) => {
    dispatch(getCurrentRichMediaStarted());
    try {
      const response = await getRichMedia(benguideId, sectionName);
      dispatch(getCurrentRichMediaSuccess(response.data.content));
    } catch (error) {
      dispatch(getCurrentRichMediaFailed(error));
    }
  };

export const uploadAdditionalDocument = (
  benguideId: string,
  file: File | null,
  fileName: string,
  resourceName: string,
  weblink: string,
  id?: string | undefined
) => {
  return async (dispatch: Dispatch) => {
    dispatch(additionalResUploadingStarted());
    try {
      await ResourceService.uploadAdditionalResource(
        benguideId,
        file,
        fileName,
        resourceName,
        weblink,
        id || ''
      );
      dispatch(additionalResUploadingCompleted());
      const { data } = await AuthService.getBenguideById(benguideId);
      dispatch(setLatestBenefitGuideCompleted(data));
    } catch (error) {
      const err = error as AxiosError;
      if (err.response)
        dispatch(additionalResUploadingFailed(err.response?.data));
    }
  };
};

export const getLatestBenguideByHash = (urlHash: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await getLatestBenefitGuideByHash(urlHash);
      dispatch(setLatestBenefitGuideCompleted(data));
    } catch (error) {
      dispatch(setLatestBenefitGuideFailed(error));
    }
  };
};

export const handleRemoveResource =
  (benguideId: string, id: string) => async (dispatch: Dispatch) => {
    dispatch(removeResourceStarted());
    try {
      await ResourceService.removeAdditionalResource(benguideId, id);
      dispatch(removeResourceCompleted());
    } catch (error) {
      dispatch(removeResourceFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const removePlanDocument =
  (planId: string, docType: string, benefitKind: string) =>
  async (dispatch: Dispatch) => {
    dispatch(removePlanDocumentStarted());
    try {
      await ResourceService.removePlanDocument(planId, docType, benefitKind);
      dispatch(allPlanDocsUpdated());
      dispatch(removePlanDocumentCompleted());
    } catch (error) {
      dispatch(removePlanDocumentFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const removePlanDocuments = (
  planId: string,
  benefitKind: string,
  documentTypeList: { docType: string; planDocumentName: string }[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      const removeDocs = async () => {
        for (const element of documentTypeList) {
          dispatch(updatePlanDocumentsStarted());
          const response = await ResourceService.removePlanDocument(
            planId,
            element.docType,
            benefitKind,
            element.planDocumentName
          );
          dispatch(updatePlanDocumentsCompleted(response.data));
        }
      };
      return removeDocs();
    } catch (error) {
      dispatch(removePlanDocumentFailed(error));
      throw error;
    }
  };
};

export const uploadPlanDocuments = (
  documentList: FileType[],
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    const planDocumentUpload = async () => {
      for (const element of documentList) {
        dispatch(uploadPlanDocumentsStarted());
        const response = await MedicalPlanService.uploadPlanDocument(
          planId,
          benefitKind,
          element.docType,
          element.file,
          element.file.name,
          element.planDocumentName
        );
        dispatch(uploadPlanDocumentsCompleted(response.data));
      }
    };
    return planDocumentUpload();
  };
};
export const uploadWeblinks = (
  weblinkList: WebLinkType[],
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(uploadPlanDocumentsStarted());
    const response = await MedicalPlanService.updateWeblinks(
      planId,
      benefitKind,
      weblinkList
    );
    dispatch(uploadPlanDocumentsCompleted(response.data));
    return response;
  };
};

export const editAdditionalWeblink = (
  weblink: WebLinkType,
  planId: string,
  benefitKind: string,
  existingPlanDocumentName: string,
  onClose?: Function
) => {
  return async (dispatch: Dispatch) => {
    dispatch(uploadPlanDocumentsStarted());
    const response = await MedicalPlanService.editWeblinks(
      planId,
      benefitKind,
      existingPlanDocumentName,
      weblink
    );
    dispatch(uploadPlanDocumentsCompleted(response.data));
    if (response.status === 200 && onClose) {
      onClose();
    }
  };
};

export const updateExistingPlanDocuments = (
  planId: string,
  benefitKind: string,
  editedDocList: EditDocType[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      const editDocument = async () => {
        for (const editedDoc of editedDocList) {
          dispatch(uploadPlanDocumentsStarted());
          const response = await MedicalPlanService.editPlanDocuments(
            planId,
            benefitKind,
            editedDoc.newResourceName,
            editedDoc.exisingResourceName
          );
          dispatch(uploadPlanDocumentsCompleted(response.data));
        }
      };
      return editDocument();
    } catch (error) {
      dispatch(uploadPlanDocumentsFailed(error));
      throw error;
    }
  };
};

export const uploadAndRemovePlanDocuments = (
  documentList: FileType[],
  planId: string,
  benefitKind: string,
  removedDocList: { docType: string; planDocumentName: string }[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      const removePlanDocs = async () => {
        for (const docType of removedDocList) {
          dispatch(updatePlanDocumentsStarted());
          const response = await ResourceService.removePlanDocument(
            planId,
            docType.docType,
            benefitKind,
            docType.planDocumentName
          );
          dispatch(updatePlanDocumentsCompleted(response.data));
          if (
            response.status === 200 &&
            docType === removedDocList[removedDocList.length - 1]
          ) {
            const planDocumentUpload = async () => {
              for (const element of documentList) {
                dispatch(uploadPlanDocumentsStarted());
                const response = await MedicalPlanService.uploadPlanDocument(
                  planId,
                  benefitKind,
                  element.docType,
                  element.file,
                  element.file.name,
                  element.planDocumentName
                );
                dispatch(uploadPlanDocumentsCompleted(response.data));
              }
            };
            return planDocumentUpload();
          }
        }
      };
      return removePlanDocs();
    } catch (error) {
      dispatch(uploadPlanDocumentsFailed(error));
      throw error;
    }
  };
};

export default resourcesSlice.reducer;
