import { FC, useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { useGetCustomNavTitle } from 'hooks/useGetCustomNavTitle';
import { RouteKeyConstants } from 'constants/routeKeyConstants';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import PageHeader from 'components/PageHeader/PageHeader';
import NoPlansView from 'components/NoPlansView/NoMDVPlansView';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import FourOOneKCard from 'modules/fourOOneK/components/FourOOneKCard';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import FourOOneKPlansChannel from 'modules/clients/UPClient/channels/FourOOneKPlansChannel';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import PlanReference from 'model/PlanReference';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import { swapElements } from 'modules/benefitsWellness/util';
import { ReactComponent as FourOOneKIcon } from 'images/pageHeaderIcons/icon-401k.svg';
import {
  fetchFourOOneKPlanList,
  addPlans,
  getRichMediaDefaultContent,
} from 'modules/fourOOneK/slices/fourOOneKSlice';
import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { BENEFIT_CATEGORY, LEGAL_DISCLAIMER } from 'constants/commonConstants';
import { FOUR_O_ONE_K_PLAN_CHANNEL } from 'modules/clients/UPClient/UPClientConst';

import styles from './fourOOneK.module.less';

const FourOOneK: FC = () => {
  const dispatch = useAppDispatch();

  const fourOOneKPlanList = useAppSelector(
    (state) => state.fourOOneK.fourOOneKPlanList.data
  );
  const fourOOneKPlanListInProgress = useAppSelector(
    (state) => state.fourOOneK.fourOOneKPlanList.inProgress
  );
  const addedPlans = useAppSelector(
    (state) => state.fourOOneK.fourOOneKSection.plans
  );
  const addedPlansLoading = useAppSelector(
    (state) => state.fourOOneK.fourOOneKSection.inProgress
  );

  const benguide = useBenguide();
  const {
    masterId,
    planYearId,
    employerId,
    isEditMode,
    benefitClasses,
    revision,
  } = benguide;
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [sortedFourOOneKPlans, setSortedPlans] = useState<any[]>([]);

  const customNavigationTitles = useGetCustomNavTitle({
    key: RouteKeyConstants.FOUR_O_ONE_K as keyof typeof RouteKeyConstants,
  });

  const reloadAllFourOOneKPlans = useCallback(() => {
    dispatch(fetchFourOOneKPlanList(employerId, planYearId, benefitClasses));
  }, [employerId, planYearId, dispatch, benefitClasses]);

  useEffect(() => {
    dispatch(fetchFourOOneKPlanList(employerId, planYearId, benefitClasses));
  }, [dispatch, employerId, planYearId, benefitClasses]);

  useEffect(() => {
    if (isEditMode && !isEmpty(addedPlans)) {
      dispatch(getRichMediaDefaultContent(masterId, addedPlans[0].id));
    }
    setSortedPlans(addedPlans || []);
  }, [isEditMode, addedPlans, dispatch, masterId, revision]);

  const moveTop = (index: number) => {
    if (addedPlansLoading) return;
    if (index > 0) {
      const newPlans = swapElements(
        sortedFourOOneKPlans,
        index,
        index - 1
      ) as any[];
      setSortedPlans(newPlans);
      updatePlans(newPlans);
    }
  };

  const moveBottom = (index: number) => {
    if (addedPlansLoading) return;
    if (index < sortedFourOOneKPlans.length - 1) {
      const newPlans = swapElements(
        sortedFourOOneKPlans,
        index,
        index + 1
      ) as any[];
      setSortedPlans(newPlans);
      updatePlans(newPlans);
    }
  };

  const updatePlans = (plans: any[]) => {
    const planReferences = plans.map((plan: PlanReference) => {
      return {
        ...plan,
        planId: plan.id,
        carrier: {
          id: get(plan, 'carrier.id', null),
          name: get(plan, 'carrier.name', null),
        },
        benefitCarrier: {
          id: get(plan, 'benefitCarrier.id', null),
          name: get(plan, 'benefitCarrier.name', null),
        },
      };
    });
    const addPlanRequest = {
      plans: planReferences,
      sectionName: BENEFIT_CATEGORY.RETIREMENT.value,
    } as AddPlanApiRequestPayload;
    dispatch(addPlans(masterId, addPlanRequest));
  };

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={styles.fourOOneKWrapper}>
        <FourOOneKPlansChannel />
        <BenefitGuideRefreshChannel
          benefitKind={BENEFIT_CATEGORY.RETIREMENT.value}
        />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <PageHeader
          pageHeaderTitle={customNavigationTitles}
          pageHeaderIcon={<FourOOneKIcon />}
          customIconStyle={styles.icon}
          managePlansButton={
            isEditMode &&
            addedPlans.length > 0 && (
              <EditableHotspot
                alwaysVisible={true}
                customModal={(visible: boolean, onClose: Function) => (
                  <AddOrRemovePlans
                    visible={visible}
                    onClose={onClose}
                    title="401(k)"
                    onSave={updatePlans}
                    addedPlanList={addedPlans}
                    allPlans={fourOOneKPlanList}
                    reloadPlans={reloadAllFourOOneKPlans}
                    channel={FOUR_O_ONE_K_PLAN_CHANNEL}
                    showAlert={showAlert}
                    isLoading={fourOOneKPlanListInProgress}
                  />
                )}
              >
                <Button>+ Add or Remove Plans</Button>
              </EditableHotspot>
            )
          }
        />

        {sortedFourOOneKPlans?.length > 0 ? (
          <div>
            {sortedFourOOneKPlans?.map((plan: any, index: number) => (
              <FourOOneKCard
                key={index}
                plan={plan}
                dataIndex={index}
                moveTop={() => moveTop(index)}
                moveBottom={() => moveBottom(index)}
                hideTop={index === 0}
                hideBottom={index === sortedFourOOneKPlans?.length - 1}
                renderReorder={sortedFourOOneKPlans?.length > 1}
              />
            ))}
          </div>
        ) : (
          isEditMode && (
            <AddOrRemovePlans
              visible
              withoutModal
              title="401(k)"
              onSave={updatePlans}
              addedPlanList={addedPlans}
              allPlans={fourOOneKPlanList}
              reloadPlans={reloadAllFourOOneKPlans}
              channel={FOUR_O_ONE_K_PLAN_CHANNEL}
              showAlert={showAlert}
              isLoading={fourOOneKPlanListInProgress}
            />
          )
        )}
        {addedPlans.length > 0 && (
          <div className={styles.disclaimer}>{LEGAL_DISCLAIMER}</div>
        )}
        {isEmpty(addedPlans) && !isEditMode && (
          <NoPlansView className={styles.noPlanView} />
        )}
      </div>
    </NotificationContextProvider>
  );
};

export default FourOOneK;
