import React, { memo, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import FixedAlertMessage from 'components/FixedAlert/FixedAlertMessage';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import {
  clearUpdateCustomNavigationTitlesError,
  getCustomNavigationTitles,
  updateCustomNavigationTitles,
} from 'modules/auth/slices/benguideSlice';
import {
  defaultRouteTitles,
  RouteKeyConstants,
} from 'constants/routeKeyConstants';
import styles from 'components/EditNavigationTitleModal/editNavigationTitleModal.module.less';

const { Text } = Typography;
type Props = {
  visible: boolean;
  titleKey: keyof typeof RouteKeyConstants | null;
  onClose: () => void;
  textLimit?: number;
};

const EditNavigationTitleModal = (props: Props) => {
  const { visible, titleKey, textLimit = 22, onClose } = props;

  const dispatch = useAppDispatch();
  const benguideState = useAppSelector((state) => state.benguide);
  const {
    customNavigationTitles,
    isCustomNavigationUpdateInProgress,
    customNavigationUpdateError,
  } = benguideState;

  const [text, setText] = useState<string>('');

  const modalTitle = customNavigationTitles?.data?.[titleKey!]
    ? customNavigationTitles?.data[titleKey!] || ''
    : defaultRouteTitles[titleKey!] || '';

  useEffect(() => {
    if (visible) {
      setText(
        customNavigationTitles?.data?.[titleKey!]
          ? customNavigationTitles?.data[titleKey!] || ''
          : defaultRouteTitles[titleKey!] || ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const checkIfChangeTriggered = (): boolean => {
    const initialTxt = customNavigationTitles?.data?.[titleKey!]
      ? customNavigationTitles?.data[titleKey!] || ''
      : defaultRouteTitles[titleKey!] || '';
    return initialTxt === text.trim();
  };

  const handleSave = () => {
    const data = {
      key: titleKey!,
      title: text.trim(),
    };
    dispatch(
      updateCustomNavigationTitles(
        benguideState?.data?.masterId,
        data,
        async () => {
          await dispatch(
            getCustomNavigationTitles(benguideState?.data?.masterId)
          );
          onClose();
        },
        () => {}
      )
    );
  };
  const handleOnClose = () => {
    onClose();
    dispatch(clearUpdateCustomNavigationTitlesError());
  };
  const textCharacterLength = text.length;
  return (
    <HalfScreenModal
      width="552px"
      title={`Edit ${modalTitle} Title`}
      visible={visible}
      className={styles.modalWrapper}
      footer={[
        <Button
          type="link"
          className={styles.resetBtn}
          key="reset"
          onClick={() => setText(defaultRouteTitles[titleKey!] || '')}
        >
          Reset to Default
        </Button>,
        <Button
          className={`${styles.btn} ${styles.cancel}`}
          key="cancel"
          onClick={handleOnClose}
          loading={isCustomNavigationUpdateInProgress}
        >
          Cancel
        </Button>,
        <Button
          className={styles.btn}
          key="submit"
          type="primary"
          onClick={handleSave}
          disabled={
            textCharacterLength > textLimit ||
            !text.trim().length ||
            checkIfChangeTriggered()
          }
          loading={isCustomNavigationUpdateInProgress}
        >
          Done
        </Button>,
      ]}
      onCancel={handleOnClose}
    >
      <FixedAlertMessage
        message={
          'Changing the navigation title will also change the page title. Please update with caution.'
        }
        type="error"
      />
      <Col xs={24} className={styles.labelWrapper}>
        <div>New Title *</div>
      </Col>
      <Row>
        <Col xs={24}>
          <Input
            className={`${styles.input} ${
              textCharacterLength > textLimit || customNavigationUpdateError
                ? styles.errorInput
                : ''
            } `}
            placeholder="Custom Title goes here..."
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              if (customNavigationUpdateError) {
                dispatch(clearUpdateCustomNavigationTitlesError());
              }
            }}
            maxLength={100}
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Text
          className={`${styles.charLimit} ${
            textCharacterLength > textLimit || customNavigationUpdateError
              ? styles.errorText
              : ''
          }`}
        >
          {customNavigationUpdateError
            ? 'Title already exists'
            : textCharacterLength > textLimit
            ? 'Character Limit reached'
            : ''}
        </Text>
        <Text
          className={`${styles.charLimit} ${
            textCharacterLength > textLimit ? styles.errorText : ''
          }`}
        >
          Character Limit: {textCharacterLength}/{textLimit}
        </Text>
      </Row>
    </HalfScreenModal>
  );
};

export default memo(EditNavigationTitleModal);
