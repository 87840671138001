import html2canvas from 'html2canvas';
import isEmpty from 'lodash/isEmpty';

export const saveScreenshot = async (node: any) => {
  if (node !== null) {
    const canvas = await html2canvas(node, { useCORS: true });
    return canvas.toDataURL('image/png');
  }
};

export const getBase64Image = async (
  imgUrl: string,
  excludeCredentials?: boolean,
  throwError?: boolean
) => {
  if (!isEmpty(imgUrl)) {
    try {
      const data = await fetch(imgUrl, {
        credentials: excludeCredentials ? undefined : 'include',
      });
      const blob = await data.blob();
      return new Promise((resolve, reject) => {
        if (!blob.type.startsWith('image') && throwError) {
          reject(new Error('image not found'));
        }
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    } catch (e) {
      console.error(e);
    }
  }
};
