import { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import reactHtmlParser from 'react-html-parser';
import isEmpty from 'lodash/isEmpty';

import { useGetCustomNavTitle } from 'hooks/useGetCustomNavTitle';
import { RouteKeyConstants } from 'constants/routeKeyConstants';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import PageHeader from 'components/PageHeader/PageHeader';
import ImageUploader from 'components/ImageUploader/ImageUploader';

import NHCEditor from 'modules/newHireChecklist/components/NHCEditor/NHCEditor';
import { ReactComponent as NewHire } from 'images/pageHeaderIcons/new-hire-checklist.svg';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SectionName } from 'modules/home/constants';
import { getSectionImageUrl } from 'modules/auth/services/BenguideService';
import editIcon from 'images/icon-edit.svg';
import NewHireChecklistDefaultBannerImage from 'images/samples/newhirechecklist.png';

import {
  getDefaultRichMedia,
  getCurrentRichMedia,
  handleSectionImageUpload,
  reloadNHCSection,
} from 'modules/newHireChecklist/slices/nhcSlice';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import { NotificationContextProvider } from 'context/NotificationContext';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import { getBase64Image } from 'utils/screenshotUtil';
import { benguideApiV2 } from 'utils/apiUtil';
import PageSection from 'model/PageSection';

import styles from './newHireChecklist.module.less';

const NewHireChecklist = () => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [originalImg, setOriginalImg] = useState<string>('');

  const location = useLocation();
  const dispatch = useAppDispatch();
  const { sections, defaultContent, currentContent } = useAppSelector(
    (state) => state.newHireChecklist
  );

  const [contentData, setContentData] = useState<string>('');
  const [displayData, setDisplayData] = useState<string>('');
  const [nhcImage, setNHCImage] = useState<string>('');
  const imageUploaderRef = useRef(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);

  const { benguide } = useAppSelector((state) => state);
  const { data: benguideData } = benguide;
  const { masterId, status, revision, latestRevision } = benguideData || {};
  const customNavigationTitles = useGetCustomNavTitle({
    key: RouteKeyConstants.NEW_HIRE_CHECKLIST as keyof typeof RouteKeyConstants,
  });

  useEffect(() => {
    if (masterId) {
      dispatch(getDefaultRichMedia(masterId, SectionName.NHC_TEXT));
      dispatch(getCurrentRichMedia(masterId, SectionName.NHC_TEXT));
      setNHCImage(
        getSectionImageUrl(
          masterId,
          SectionName.NHC_BANNER_IMAGE,
          latestRevision || revision
        )
      );
      dispatch(reloadNHCSection(masterId));
    }
  }, [dispatch, masterId, status, latestRevision, revision]);

  useEffect(() => {
    if (!isEmpty(defaultContent)) {
      if (
        defaultContent.localeCompare(currentContent) === 0 ||
        isEmpty(currentContent)
      ) {
        setContentData(defaultContent);
        setDisplayData(defaultContent);
      } else {
        setContentData(currentContent);
        setDisplayData(currentContent);
      }
    }
  }, [currentContent, defaultContent]);

  const updateEditorContent = (data: string) => {
    setContentData(data);
  };

  const resetToCurrentValue = () => {
    if (!isEmpty(displayData)) {
      setContentData(displayData);
    }
  };

  const resetToDefault = () => {
    if (!isEmpty(defaultContent)) {
      setContentData(defaultContent);
    }
  };

  const handleImageUpload = (
    image: string,
    originalImage?: string,
    cropArea?: OriginalImageCropParams
  ) => {
    setLoadingImage(true);
    setNHCImage(image);
    dispatch(
      handleSectionImageUpload(
        masterId,
        image,
        SectionName.NHC_BANNER_IMAGE,
        originalImage,
        cropArea
      )
    );
  };

  const getContentData = () => {
    let content;
    const search = location.search;
    const previewToken = new URLSearchParams(search).get('previewToken');

    if (previewToken) {
      const appendToken = `previewToken=${previewToken}`;
      content = displayData.replaceAll('previewToken', appendToken);
    } else {
      content = displayData.replaceAll('?previewToken', '');
    }
    return reactHtmlParser(content);
  };

  useEffect(() => {
    if (sections) {
      const imgSection = sections.find(
        (section: PageSection) => section.name === SectionName.NHC_BANNER_IMAGE
      );
      if (imgSection?.data?.originalImageReference) {
        getBase64Image(
          `${benguideApiV2}/${masterId}/images?section-name=${
            SectionName.NHC_BANNER_IMAGE
          }&original=true&revision=${latestRevision || revision}`
        ).then((data: any) => {
          setOriginalImg(data);
          if (loadingImage) {
            (imageUploaderRef?.current as any)?.close();
            setLoadingImage(false);
          }
        });
      }
    }
    // No need call when change loadingImage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterId, sections]);

  const sectionImageCrop = useMemo(() => {
    if (sections) {
      const imgSection = sections?.find(
        (section: PageSection) => section.name === SectionName.NHC_BANNER_IMAGE
      );

      return imgSection
        ? (imgSection as PageSection).data.originalImageCropParams
        : null;
    }
    return null;
  }, [sections]);

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      <div className={styles.nhcWrapper} ref={textRef}>
        <BenefitGuideRefreshChannel />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <PageHeader
          pageHeaderTitle={customNavigationTitles}
          pageHeaderIcon={<NewHire className={styles.nhcIcon} />}
        />
        <EditableHotspot
          alwaysVisible={false}
          editText="Edit"
          editIcon={<img src={editIcon} alt="edit" />}
          customModal={(visible: boolean, onClose: Function) => (
            <ImageUploader
              title="Photo"
              cropShape="rect"
              uploadedImage={nhcImage}
              aspect={1}
              loading={false}
              onConfirm={handleImageUpload}
              showOperations
              uploadNewImgText="+ Upload New Photo"
              isOpenImageTray={visible}
              onCloseImgTray={onClose}
              uploadImgAfterResetText="Upload Photo"
              hideUploader
              customCropper={{ width: 780, height: 248 }}
              defaultLogo={NewHireChecklistDefaultBannerImage}
              imagePreviewStyle={styles.nhcMainImage}
              uploadWrapperClass={styles.nhcUploadWrapper}
              maxSizeMB={4}
              defaultCropArea={sectionImageCrop}
              unCroppedImage={originalImg}
              ref={imageUploaderRef}
              preventCloseOnConfirm={true}
              saving={loadingImage}
            />
          )}
        >
          {<></>}
        </EditableHotspot>

        <div className={styles.textArea}>
          <EditableHotspot
            alwaysVisible={false}
            editText="Edit Text"
            editIcon={<img src={editIcon} alt="edit" />}
            customModal={(visible: boolean, onClose: Function) => (
              <NHCEditor
                isOpenModal={visible}
                closeModal={onClose}
                isResetEnabled
                editorContent={contentData}
                updateEditorContent={updateEditorContent}
                resetToCurrentValue={resetToCurrentValue}
                resetToDefault={resetToDefault}
                benguideId={masterId}
                defaultContent={displayData}
              />
            )}
          >
            <div className={styles.nhcContent}>{getContentData()}</div>
          </EditableHotspot>
        </div>
      </div>
    </NotificationContextProvider>
  );
};

export default NewHireChecklist;
