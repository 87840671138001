import { Dispatch } from 'redux';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as BenguideService from 'modules/auth/services/BenguideService';
import {
  BENGUIDE_MODE_EDIT,
  BENGUIDE_MODE_VIEW,
} from 'modules/auth/constants/globleConstants';
import { benefitCategory } from 'modules/auth/constants/commonConstants';
import { SectionName } from 'modules/home/constants';
import { updateMedicalSection } from 'modules/benefitsWellness/slices/medicalSlice';
import { updateDentalSection } from 'modules/benefitsWellness/slices/dentalSlice';
import { updateVisionSection } from 'modules/benefitsWellness/slices/visionSlice';
import { updateTelehealthSection } from 'modules/benefitsWellness/slices/telehealthSlice';
import { updateWellbeingSection } from 'modules/benefitsWellness/slices/wellbeingSlice';

import {
  updateChecklistText,
  updateHomeContentPageSuccess,
  updateOverivewText,
} from 'modules/home/slices/homeSlice';
import { RouteKeyConstants } from 'constants/routeKeyConstants';
import BenefitGuide from 'model/BenefitGuide';
import StyleConfiguration from 'model/StyleConfiguration';
import ContentPage from 'model/CotentPage';
import PlanYear from 'model/PlanYear';
import Individual from 'model/Individual';
import { findIndividualById } from 'services/IndividualService';
import { getLoginByUsername } from 'services/LoginService';
import { updateHolidayAndTimeOffSection } from 'modules/holidaysAndTimeOff/slices/holidayAndTimeOffSlice';
import {
  updateLifeAndDisaPlanSection,
  updateLifeSection,
} from 'modules/benefitsWellness/slices/lifeAndDisabilitySilce';
import { updateFamilyAndLeaveSection } from 'modules/familyAndLeave/slices/familyAndLeaveSlice';
import { updateAdditionalPerksSection } from 'modules/additionalPerks/slices/additionalPerksSlice';
import { updateVoluntaryBenefitsSection } from 'modules/benefitsWellness/slices/voluntaryBenefitsSlice';
import { updateFourOOneKSection } from 'modules/fourOOneK/slices/fourOOneKSlice';
import { updateHSASection } from 'modules/taxAdvantaged/slices/hsaSlice';
import { updateHRASection } from 'modules/taxAdvantaged/slices/hraSlice';
import { updateCommuterSection } from 'modules/taxAdvantaged/slices/commuterSlice';
import { updateFSASection } from 'modules/taxAdvantaged/slices/fsaSlice';
import { setStyleVariables } from 'utils/stylesUtil';

interface BenguideState {
  inProgress: boolean;
  error: any;
  data: any;
  mode: string;
  hotspotted: boolean;
  planYear: PlanYear | null;
  planYearLoading: boolean;
  lastUpdatedUser: Individual | null;
  createdUser: Individual | null;
  hotspottedSection: string | null;
  updateBenguideStylesSuccess: boolean;
  closeModalsInUP: boolean;
  sectionImageReference: any;
  loggedInUserType: string | null;
  customNavigationTitles: {
    data: { [K in keyof typeof RouteKeyConstants]: string } | null;
    inProgress: boolean;
    error: any;
  };
  isCustomNavigationUpdateInProgress: boolean;
  customNavigationUpdateError: any;
}

const initialState = {
  inProgress: false,
  planYearLoading: false,
  error: null,
  data: null,
  mode: BENGUIDE_MODE_VIEW,
  hotspotted: false,
  lastUpdatedUser: null,
  createdUser: null,
  hotspottedSection: null,
  updateBenguideStylesSuccess: false,
  closeModalsInUP: false,
  customNavigationTitles: {
    data: null,
    inProgress: false,
    error: null,
  },
  isCustomNavigationUpdateInProgress: false,
  customNavigationUpdateError: null,
} as BenguideState;

type PlanYearFetchData = {
  employerId: string;
  planYearId: string;
};

const getIndividualWithUsername = async (username: string) => {
  const loginResponse = await getLoginByUsername(username);
  const response = await findIndividualById(loginResponse.data.individualId);
  return response.data;
};

export const fetchPlanYearById = createAsyncThunk<PlanYear, PlanYearFetchData>(
  'benguide/fetchPlanYearById',
  async ({ employerId, planYearId }) => {
    const response = await BenguideService.getPlanYearData(
      employerId,
      planYearId
    );
    return response.data;
  }
);

export const fetchCreatedUserByLoginId = createAsyncThunk(
  'benguide/fetchCreatedUserByLoginId',
  async (loginId: string) => {
    return await getIndividualWithUsername(loginId);
  }
);

export const fetchLastUpdatedUserByLoginId = createAsyncThunk(
  'benguide/fetchLastUpdatedUserByLoginId',
  async (loginId: string) => {
    return await getIndividualWithUsername(loginId);
  }
);

const benguideSlice = createSlice({
  name: 'benguide',
  initialState,
  reducers: {
    initBenguideInProgress: (state) => {
      state.inProgress = true;
      state.data = null;
      state.error = null;
    },
    initBenguideCompleted: (state, { payload }) => {
      state.inProgress = false;
      state.data = payload;
      state.error = null;
    },
    initBenguideFailed: (state, { payload }) => {
      state.inProgress = false;
      state.data = null;
      state.error = payload;
    },
    setBenguideMode: (state, { payload }) => {
      state.mode = payload;
    },
    setBenguideHotspotted: (state, { payload }) => {
      state.hotspotted = payload.state;
      state.hotspottedSection = payload.section;
    },
    updateProcessedBenguideCompleted: (state, { payload }) => {
      state.data = payload;
    },
    benguideRevisionUpdateCompleted: (state) => {
      state.data = {
        ...state.data,
      } as BenefitGuide;
    },
    benguideStylesUpdateCompleted: (state, { payload }) => {
      state.data = {
        ...state.data,
        styles: payload,
      } as BenefitGuide;
      state.updateBenguideStylesSuccess = true;
    },
    benguideHomePageUpdateCompleted: (state, { payload }) => {
      state.data = {
        ...state.data,
        homePage: payload,
      } as BenefitGuide;
    },
    benguideFrequencyUpdateCompleted: (state, { payload }) => {
      state.data = {
        ...state.data,
        contributionFrequencies: payload,
      } as BenefitGuide;
    },
    enableEditMode: (state) => {
      state.mode = BENGUIDE_MODE_EDIT;
    },
    loggedInUserType(state, action: PayloadAction<string>) {
      state.loggedInUserType = action.payload;
    },
    setCloseModalsInUP: (state, { payload }) => {
      state.closeModalsInUP = payload;
    },
    setPlanRecommenderState: (state, { payload }) => {
      state.data.planRecommenderEnabled = payload;
    },
    benguideLatestRevisionUpdateCompleted: (state, { payload }) => {
      state.data = {
        ...state.data,
        latestRevision: payload,
      } as BenefitGuide;
    },
    getCustomNavigationTitlesInProgress: (state) => {
      state.customNavigationTitles.inProgress = true;
      state.customNavigationTitles.error = null;
    },
    getCustomNavigationTitlesSuccess: (state, { payload }) => {
      state.customNavigationTitles.data = payload;
      state.customNavigationTitles.inProgress = false;
      state.customNavigationTitles.error = null;
    },
    getCustomNavigationTitlesFailed: (state, { payload }) => {
      state.customNavigationTitles.error = payload;
      state.customNavigationTitles.inProgress = false;
    },
    updateCustomNavigationTitlesInProgress: (state) => {
      state.isCustomNavigationUpdateInProgress = true;
      state.customNavigationUpdateError = null;
    },
    updateCustomNavigationTitlesSuccess: (state) => {
      state.isCustomNavigationUpdateInProgress = false;
      state.customNavigationUpdateError = null;
    },
    updateCustomNavigationTitlesFailed: (state, { payload }) => {
      state.isCustomNavigationUpdateInProgress = false;
      state.customNavigationUpdateError = payload;
    },
    clearUpdateCustomNavigationTitlesError: (state) => {
      state.customNavigationUpdateError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlanYearById.fulfilled, (state, { payload }) => {
      state.planYearLoading = false;
      state.planYear = payload;
    });
    builder.addCase(fetchPlanYearById.pending, (state) => {
      state.planYearLoading = true;
    });
    builder.addCase(fetchPlanYearById.rejected, (state) => {
      state.planYearLoading = false;
      state.planYear = null;
    });
    builder.addCase(
      fetchLastUpdatedUserByLoginId.fulfilled,
      (state, { payload }) => {
        state.lastUpdatedUser = payload;
      }
    );
    builder.addCase(
      fetchCreatedUserByLoginId.fulfilled,
      (state, { payload }) => {
        state.createdUser = payload;
      }
    );
  },
});

export const {
  initBenguideCompleted,
  initBenguideInProgress,
  initBenguideFailed,
  setBenguideMode,
  setBenguideHotspotted,
  updateProcessedBenguideCompleted,
  benguideRevisionUpdateCompleted,
  benguideStylesUpdateCompleted,
  benguideHomePageUpdateCompleted,
  enableEditMode,
  loggedInUserType,
  benguideFrequencyUpdateCompleted,
  setCloseModalsInUP,
  setPlanRecommenderState,
  benguideLatestRevisionUpdateCompleted,
  getCustomNavigationTitlesInProgress,
  getCustomNavigationTitlesSuccess,
  getCustomNavigationTitlesFailed,
  updateCustomNavigationTitlesInProgress,
  updateCustomNavigationTitlesSuccess,
  updateCustomNavigationTitlesFailed,
  clearUpdateCustomNavigationTitlesError,
} = benguideSlice.actions;

export default benguideSlice.reducer;

export function initBenGuide(unprocessedPlanData: any): any {
  return async (dispatch: Dispatch) => {
    dispatch(initBenguideInProgress());
    dispatch(
      getCustomNavigationTitlesSuccess(
        unprocessedPlanData?.customNavigationTitles
      )
    );
    dispatch(
      updateMedicalSection(
        unprocessedPlanData.medical,
        unprocessedPlanData?.masterId
      )
    );
    dispatch(updateDentalSection(unprocessedPlanData.dental));
    dispatch(updateVisionSection(unprocessedPlanData.vision));
    dispatch(updateTelehealthSection(unprocessedPlanData.telehealth));
    dispatch(updateWellbeingSection(unprocessedPlanData.wellbeing));
    dispatch(updateFourOOneKSection(unprocessedPlanData.fourOOneK));
    dispatch(
      updateHolidayAndTimeOffSection(unprocessedPlanData.holidaysAndTimeOff)
    );
    dispatch(updateLifeSection(unprocessedPlanData.lifeAndDisability));
    dispatch(updateFamilyAndLeaveSection(unprocessedPlanData.familyAndLeave));
    dispatch(updateAdditionalPerksSection(unprocessedPlanData.additionalPerks));
    dispatch(updateVoluntaryBenefitsSection(unprocessedPlanData.volBenefits));
    dispatch(updateHSASection(unprocessedPlanData.hsa));
    dispatch(updateHRASection(unprocessedPlanData.hra));
    dispatch(updateCommuterSection(unprocessedPlanData.commuter));
    dispatch(updateFSASection(unprocessedPlanData.fsa));
    dispatch(updateHomeContentPageSuccess(unprocessedPlanData.homePage));

    const data = processPlansObject(unprocessedPlanData);
    const { employerId, masterId, revision, latestRevision } = data || {};
    const planYearResponse = await BenguideService.getPlanYearData(
      employerId,
      data.planYearId
    );
    const { data: planYearData } = planYearResponse;

    const overviewResponse = await BenguideService.getRichMedia(
      masterId,
      SectionName.HOME_OVERVIEW_TEXT
    );
    const { data: overviewText } = overviewResponse;
    const checkListResponse = await BenguideService.getRichMedia(
      masterId,
      SectionName.HOME_BENEFIT_GUIDE_DESCRIPTION
    );
    const { data: checkListText } = checkListResponse;

    dispatch(updateOverivewText(overviewText));
    dispatch(updateChecklistText(checkListText));

    const defaultOverviewResponse = await BenguideService.getDefaultRichMedia(
      masterId,
      SectionName.HOME_OVERVIEW_TEXT
    );
    const { data: defaultOverview } = defaultOverviewResponse;

    const defaultChecklistResponse = await BenguideService.getDefaultRichMedia(
      masterId,
      SectionName.HOME_BENEFIT_GUIDE_DESCRIPTION
    );
    const { data: defaultChecklist } = defaultChecklistResponse;
    let overviewImage = null;
    try {
      const imageResponse = await BenguideService.getSectionImage(
        masterId,
        SectionName.HOME_MAIN_IMAGE,
        latestRevision || revision
      );
      overviewImage = imageResponse.data;
    } catch (error) {
      // No image has been uploaded or image error
    }

    const processedBenguide = {
      ...unprocessedPlanData,
      startDate: planYearData.startDate,
      endDate: planYearData.endDate,
      overviewText: overviewText && overviewText.content,
      checkListText: checkListText && checkListText.content,
      overviewImage: overviewImage,
      defaultOverview: defaultOverview && defaultOverview.content,
      defaultChecklist: defaultChecklist && defaultChecklist.content,
      isAdvanceProfile: data.isAdvanceProfile,
    };

    dispatch(initBenguideCompleted(processedBenguide));
  };
}

export const triggerHotspots = (section: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setBenguideHotspotted({ section: section, state: true }));

    setTimeout(() => {
      dispatch(setBenguideHotspotted({ section: section, state: false }));
    }, 1500);
  };
};

function processPlansObject(plansObject: any) {
  const { taxAdvantagedAccountPlans = {} } = plansObject;
  const { plans = [], enabled } = taxAdvantagedAccountPlans;
  const commuterPlans = plans.filter(
    (plan: { benefitCategory: string }) =>
      plan.benefitCategory === benefitCategory.COMMUTER.value
  );
  const savingPlans = plans.filter(
    (plan: { benefitCategory: string }) =>
      plan.benefitCategory !== benefitCategory.COMMUTER.value
  );
  plansObject.commuterPlans = {
    ...plansObject.commuterPlans,
    plans: commuterPlans,
  };
  plansObject.savingsPlans = {
    ...plansObject.savingsPlans,
    plans: savingPlans,
    enabled,
  };
  delete plansObject.taxAdvantagedAccountPlans;
  return plansObject;
}

export const updateProcessedBenguide = (processedBenguide: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateProcessedBenguideCompleted(processedBenguide));
  };
};

export const updateBenguideRevision: any = () => {
  return async (dispatch: Dispatch) => {
    dispatch(benguideRevisionUpdateCompleted());
  };
};

export const updateBenguideStyles: any = (styles: StyleConfiguration) => {
  return async (dispatch: Dispatch) => {
    setStyleVariables(styles);
    dispatch(benguideStylesUpdateCompleted(styles));
  };
};

export const updateBenguideHomePage: any = (homePage: ContentPage) => {
  return async (dispatch: Dispatch) => {
    dispatch(benguideHomePageUpdateCompleted(homePage));
  };
};

export const updateBenguideLatestRevision: any = (revision: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(benguideLatestRevisionUpdateCompleted(revision));
  };
};

export function updatePlansForBenGuide(benguide: any): any {
  return async (dispatch: Dispatch) => {
    dispatch(initBenguideInProgress());
    dispatch(updateMedicalSection(benguide.medical, benguide?.masterId));
    dispatch(updateDentalSection(benguide.dental));
    dispatch(updateVisionSection(benguide.vision));
    dispatch(updateTelehealthSection(benguide.telehealth));
    dispatch(updateWellbeingSection(benguide.wellbeing));
    dispatch(updateFourOOneKSection(benguide.fourOOneK));
    dispatch(updateHolidayAndTimeOffSection(benguide.holidaysAndTimeOff));
    dispatch(updateLifeAndDisaPlanSection(benguide.lifeAndDisability));
    dispatch(updateFamilyAndLeaveSection(benguide.familyAndLeave));
    dispatch(updateAdditionalPerksSection(benguide.additionalPerks));
    dispatch(updateVoluntaryBenefitsSection(benguide.volBenefits));
    dispatch(updateHSASection(benguide.hsa));
    dispatch(updateHRASection(benguide.hra));
    dispatch(updateCommuterSection(benguide.commuter));
    dispatch(updateFSASection(benguide.fsa));
  };
}

export const getCustomNavigationTitles =
  (benguideMasterId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(getCustomNavigationTitlesInProgress());
      const response = await BenguideService.getCustomNavigationTitles(
        benguideMasterId
      );
      dispatch(getCustomNavigationTitlesSuccess(response?.data));
    } catch (error) {
      dispatch(getCustomNavigationTitlesFailed(error?.response?.code));
    }
  };

export const updateCustomNavigationTitles =
  (
    benguideMasterId: string,
    data: {
      key: keyof typeof RouteKeyConstants | null;
      title: string;
    },
    onSuccess: () => void,
    onError: () => void
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(updateCustomNavigationTitlesInProgress());
      await BenguideService.updateCustomNavigationTitles(
        benguideMasterId,
        data
      );
      dispatch(updateCustomNavigationTitlesSuccess());
      onSuccess();
    } catch (error) {
      dispatch(updateCustomNavigationTitlesFailed(error?.response?.data?.code));
      onError();
    }
  };
